import React, { useState, useRef } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import { motion, type HTMLMotionProps } from 'framer-motion'
import { useOnClickOutside } from 'usehooks-ts'

const OverlayStyles = cva('flex h-full flex-col bg-white shadow-xl relative py-8 border-primary-light', {
  variants: {
    overlaySide: {
      left: 'rounded-r-2xl border-r-[6px]',
      right: 'rounded-l-2xl border-l-[6px]'
    },
  },
  defaultVariants: {
    overlaySide: 'right'
  }
})

type OverlayComponentProps = 
  {
    children?: React.ReactNode,
    onClickOutside: () => void
  } 
  & VariantProps<typeof OverlayStyles>
  & HTMLMotionProps<'div'>

/**
 * `Overlay` is a component to create an overlay on the screen which animates from the side specified in the `overlaySide` prop. 
 * Clicking outside the overlay area will trigger the `onClickOutside` hook
 * 
 * @component
 * @example
 * <AnimatePresence>
 *   {condition && (
 *     <Overlay
 *       onClickOutside={() => {
 *         // Handle outside click
 *       }}
 *       overlaySide="left" // Overlay will slide from the left
 *     >
 *       // Your content here
 *     </Overlay>
 *   )}
 * </AnimatePresence>
 * 
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} [props.children] - The content to be displayed within the overlay.
 * @param {function} props.onClickOutside - Function to be called when an outside click is detected.
 * @param {string} [props.overlaySide] - The side from which the overlay should animate; either "left" or "right". Defaults to "right".
 * @param {...HTMLMotionProps<'div'>} props - Additional properties spread to the underlying motion div.
 */
export const Overlay:React.FC<OverlayComponentProps> = ({ onClickOutside, children, overlaySide = 'right', ...props }) => {

  const overlayRef = useRef(null)
  useOnClickOutside(overlayRef, onClickOutside)

  return (
    <>
      {/* Background overlay */}
      <motion.div 
        className="fixed inset-0 bg-gray-500 bg-opacity-75 z-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 100 }}
        exit={{ opacity: 0 }}
      ></motion.div>

      <div className="fixed inset-0 overflow-hidden z-10">
        <div className="absolute inset-0 overflow-hidden">
          <div className={`pointer-events-none fixed flex max-w-full inset-y-0 ${overlaySide == 'right' ? ' right-0 pl-10' : 'left-0 pl-10'}`}>
            <div className="pointer-events-auto w-screen max-w-xl">

              {/* Main content */}
              <motion.div 
                ref={overlayRef}
                className={twMerge( 
                  OverlayStyles({ overlaySide }), 
                  props.className, 
                )}
                initial={{ opacity: 0, left: '100%' }}
                animate={{ opacity: 100, left: '0' }}
                exit={{ opacity: 0,left: '100%' }}
              >
                {children}
              </motion.div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}