import { extendTailwindMerge } from 'tailwind-merge'

export default extendTailwindMerge({
  classGroups: {
    text: [
      'text-primary-darker',
      'text-primary-dark',
      'text-primary',
      'text-primary-light',
      'text-primary-lighter',
      'text-accent',
      'text-dark',
      'text-light'
    ],
    background: [
      'bg-primary-darker',
      'bg-primary-dark',
      'bg-primary',
      'bg-primary-light',
      'bg-primary-lighter',
      'bg-accent',
      'bg-dark',
      'bg-light'
    ],
    sizes: [
      'text-display-lg',
      'text-display-md',
      'text-display-sm',
      'text-display-xs',

      'text-heading-2xl',
      'text-heading-xl',
      'text-heading-lg',
      'text-heading-md',
      'text-heading-sm',
      'text-heading-xs',

      'text-label-lg',
      'text-label-md',
      'text-label-sm',
      'text-label-xs',

      'text-paragraph-lg',
      'text-paragraph-md',
      'text-paragraph-sm',
      'text-paragraph-xs'
    ]
  }
});