import React, { useState } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import { motion, type HTMLMotionProps } from 'framer-motion'
import { omit } from 'lodash'

import WallPaperGreen1 from '../img/wallpapers/Waves1.webp'
import WallPaperGreen2 from '../img/wallpapers/Waves2.webp'
import WallPaperGreen3 from '../img/wallpapers/Waves3.webp'

export const CardStyles = cva('rounded-lg shadow-lg p-4 relative', {
  variants: {
    cardBorder: {
      left: 'border-l-[6px] border-primary-lighter ring-inset ring-1 ring-neutral-100',
      right: 'border-r-[6px] border-primary-lighter ring-inset ring-1 ring-neutral-100',
      none: 'ring-0'
    },
    cardStyle: {
      unstyled: 'bg-white',
      withBg: '[&>*]:z-10'
    }
  },
  defaultVariants: {
    cardBorder: 'none',
    cardStyle: 'unstyled'
  }
})

type CardComponentProps = 
  {
    children?: React.ReactNode,
    bgVariant?: '1' | '2' | '3'
  } 
  & VariantProps<typeof CardStyles>
  & HTMLMotionProps<'div'>

export const Card:React.FC<CardComponentProps> = ({ bgVariant = '1', cardBorder, cardStyle, children, ...props }) => {

  const background:string = 
    bgVariant == '1' ? WallPaperGreen1.src :
    bgVariant == '2' ? WallPaperGreen2.src : 
    bgVariant == '3' ? WallPaperGreen3.src : ''

  return (
    <motion.div
      className={twMerge( 
        CardStyles({ cardBorder, cardStyle }), 
        props.className, 
      )}
      {...omit(props, 'className') }
    >
      {/* Background image */}
      {cardStyle == 'withBg' && (
        <div 
          className={`bg-cover rounded-lg absolute !-z-10 inset-0 ${bgVariant !== '1' && 'bg-center'}`}
          style={{
            backgroundImage: `url(${background})`,
            transform: bgVariant == '2' ? 'rotateX(180deg)' : ''
          }}
        />
      )}

      {children}
    </motion.div>
  )
}