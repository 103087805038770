import { createEnv } from "@t3-oss/env-nextjs"
import { z } from "zod"

export const env = createEnv({
  
  /**
   * All the server ENV variables go in here. They are checked to NOT get exposed to the client-side.
   */
  server: {
    //#region Vercel KV (Redis)
    KV_URL: z.string()
      .min(1, 'Moet gespecificeerd zijn')
      .startsWith('redis://', 'Moet een geldige Redis URL zijn')
      .describe('De Vercel KV URL is een Redis-instantie die gevonden kan worden in het Vercel dashboard.'),

    KV_REST_API_URL: z.string()
      .min(1, 'Moet gespecificeerd zijn')
      .url('Moet een geldige URL zijn')
      .describe('De REST-API URL om Vercel KV te bereiken. Wordt automatisch meegegeven door Vercel zelf.'),

    KV_REST_API_TOKEN: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('De REST-API token die gebruikt wordt voor Vercel KV.'),

    KV_REST_API_READ_ONLY_TOKEN: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('De Read-only token voor Vercel KV REST-API.'),
    //#endregion
    
    //#region Authentication
    NEXTAUTH_SECRET: z
      .string()
      .min(32, 'Moet minimaal 32 karakters lang zijn')
      .describe('De secret key die Nextauth gebruikt voor versleuteling van JWTs.'),

    NEXTAUTH_URL: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .url('Moet een geldige URL zijn')
      .default(
        process.env.VERCEL_ENV == 'preview' ? `https://${process.env.VERCEL_URL}` 
        : process.env.VERCEL_ENV == 'production' ? `https://${process.env.NEXT_PUBLIC_TENANT_ID.replaceAll('sksc-', '')}.skip-scanner.io`
        : `http://localhost:3000`
      )
      .describe('De base URL van je applicatie voor interne redirects en API calls.'),

    SKSC_EMPLOYEE_PASSWORD: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('Het wachtwoord dat gebruikt wordt om Skip Scanner medewerkers in een systeem toe te voegen.'),
    //#endregion
    
    //#region OpenAI
    OPENAI_API_KEY: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('De API-key om de services van OpenAI te gebruiken.'),

    OPENAI_ORGANISATION: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('De unieke identifier voor je organisatie in OpenAI. Benodigd om aanvragen te verdelen voor facturen.'),
    //#endregion
    
    //#region Firebase 
    FIREBASE_ADMIN_CONFIG: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('De complete JSON-configuratie voor de Admin SDK. Alleen voor gebruik op de server.'),
    //#endregion

    //#region Sentry.io
    SENTRY_ORG_AUTH_TOKEN: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('De organisatie-specifieke authenticatietoken om met Sentry te verbinden.')
    //#endregion
  },

  /**
   * ENV variables that are also accessible in the browser.
   */
  client: {

    NEXT_PUBLIC_NODE_ENV: z
      .enum(['development', 'preview', 'production'])
      .default(process.env.VERCEL_ENV ?? 'development')
      .describe('De applicatie-runtime omgeving. Wordt automatisch bepaald bij opstarten.'),

    NEXT_PUBLIC_TENANT_ID: z
      .string()
      .refine((value) => /((\w|\d)+(-?)(\w|\d)+)|(\w|\d)+/.test(value), 'Tenant-ID is van ongeldig formaat.')
      .describe('De ID van de tenant waar de applicatie voor draait. Begint met "sksc-".'),

    //#region Firebase (client)
    NEXT_PUBLIC_FIREBASE_API_KEY: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('De API-sleutel voor Firebase, nodig voor Firebase-services. Beschikbaar op de client.'),

    NEXT_PUBLIC_FIREBASE_APP_ID: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('De applicatie-ID van de Firebase applicatie. Beschikbaar op de client.'),

    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('Het authenticatiedomein voor Firebase Auth. Beschikbaar op de client.'),

    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('De messaging sender ID voor Firebase Cloud Messaging. Beschikbaar op de client.'),

    NEXT_PUBLIC_FIREBASE_PROJECT_ID: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('Het project-ID in de Firebase console. Beschikbaar op de client.'),

    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('De opslagbucket voor Firebase Storage. Beschikbaar op de client.'),
    //#endregion

    //#region Sentry
    NEXT_PUBLIC_SENTRY_DSN: z
      .string()
      .min(1, 'Moet gespecificeerd zijn')
      .describe('De connectiestring die Sentry gebruikt om errors door te sturen.'),
    //#endregion
  },

  /**
   * Makes the client variables available inside Next.js.
   */
  experimental__runtimeEnv: {
    NEXT_PUBLIC_NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV,
    NEXT_PUBLIC_TENANT_ID: process.env.NEXT_PUBLIC_TENANT_ID,
    NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    NEXT_PUBLIC_FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  },
  
})