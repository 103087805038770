import { z } from "zod"

/**
 * Defines the different feature flag keys that can be used in the application.
 */
export const FeatureFlagKeySchema = z.union([
  z.literal("oauth.microsoft-azure-ad").describe('Enables Microsoft Azure AD OAuth'),
  z.literal("login-page.with-custom-banner").describe('Enables a custom banner on the login page'), 
  z.literal("ui.custom-student-card").describe('Enables the schools design of the student card in the reports page'), 
  z.literal("ui.custom-student-pictures").describe('Fetching for custom student pictures from an external source') 
]).describe('The different feature flag keys that can be used for custom implementations.')

/**
 * Defines the layout of the standard feature flag implementation.
 * Implemented in Zod so runtime type-checking is still possible.
 */
export const FeatureFlagSchema = z.object({
  enabled: z.boolean(),
  impl_type: z.union([
    z.literal("function"),
    z.literal("component"),
    z.literal("page")
  ]),
  impl_path: z.string()
}).nullable()

/**
 * Defines the different modules that can be used in the application. 
 * Also defines default values.
 * 
 * **Notice:** The difference between a module and a feature flag is that a module
 * is always enabled, but only specifies one of many different default implementations.
 * A feature flag can be enabled or disabled, and specifies a custom implementation / override of
 * a default function in the application.
 */
export const ModuleSchema = z.object({
  schedule: z.union([
    z.literal('zermelo').describe('Use the Zermelo schedule provider for retrieving appointments.'),
    z.literal('mock').describe('Use a mock schedule provider for retrieving appointments. For testing purposes only.')
  ]).default('zermelo').describe('The schedule provider to use for retrieving appointments.'),
})

/**
 * The keys of the different available feature flags in the application.
 */
export type FeatureFlagKey = z.infer<typeof FeatureFlagKeySchema>

/**
 * Represents a feature flag.
 * It can be either null or an object with the following properties:
 * - `enabled`: a boolean indicating whether the feature is enabled or not.
 * - `impl_type`: a string indicating the type of implementation (`function`, `component` or `page`).
 * - `impl_path`: a string representing the relative import path to the implementation file.
 * 
 * The path should point to a default export.
 */
export type FeatureFlag = z.infer<typeof FeatureFlagSchema>

/**
 * All the different modules that can be used in the application.
 */
export type Modules = z.infer<typeof ModuleSchema>

/**
 * The values of the different available modules in the application.
 * Created for automatic type-hinting when retrieving the module values.
 */
export type ModuleValues = {
  [K in keyof Modules]: Modules[K]
}

/**
 * The configuration of a tenant. Includes the feature flags and modules.
 */
export type TenantConfig = {
  featureFlags: Record<FeatureFlagKey, FeatureFlag>
  modules: Modules
}