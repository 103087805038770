export * from './tenant'
export * from './notification'
// export * from './search'
export * from './sksc/database'
export * from './appointment'
export * from './schedule'

import type { UseBoundStore, StoreApi } from 'zustand'
import { createContext, useContext, useState, useEffect, type ReactNode } from 'react'
import { StateStorage } from 'zustand/middleware'
import { get, set, del } from 'idb-keyval'

/**
 * Higher-order function to create a context provider and a custom hook for a given Zustand store.
 * 
 * @param createStore - A factory function that creates a new Zustand store.
 * @returns A tuple containing the context provider and the custom hook.
 */
export function createStoreContext<TState>(createStore: () => UseBoundStore<StoreApi<TState>>) {

  /**
   * React context created to provide the Zustand store to components. 
   * This context will hold the Zustand store created by `createStore`.
   */
  const StoreContext = createContext<UseBoundStore<StoreApi<TState>>>(createStore());

  /**
   * React component that provides the Zustand store to its children components. 
   * It uses the `createStore` function to create a store instance and provides it via `StoreContext`.
   */
  const StoreProvider:React.FC<{ children: ReactNode }> = ({ children }) => {
    const [ useStore ] = useState(createStore);
    return (
      <StoreContext.Provider value={useStore}>
        {children}
      </StoreContext.Provider>
    ) 
  };

  /**
   * Custom hook that provides access to the Zustand store within components. 
   * It uses `useContext` to access the store from `RowStoreContext` and returns the hook returned by the Zustand store.
   */
  const useStore = () => {
    const useStore = useContext(StoreContext);
    return useStore();
  };

  return [ StoreProvider, useStore ] as const;
}

/**
 * Storage implementation for Zustand that uses IndexedDB via localforage.
 */
export const indexedDBStorage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    
    return new Promise((resolve) => {

      if (typeof window === "undefined") resolve(null);
      else setTimeout(async () => {
        const value = await get(name);
        resolve(value || null);
      }, 0);
      
    })
  },
  setItem: async (name: string, value: string): Promise<void> => {
    // Exit early on server                                               
    if (typeof indexedDB === 'undefined') {                                                                         
      return                                                                                                   
    }                                                                                                                                                                                          
    set(name, value)  
  },
  removeItem: async (name: string): Promise<void> => {
    // Exit early on server                                               
    if (typeof indexedDB === 'undefined') {                                                                         
      return                                                                                                   
    }                                                                                                                                                                                          
    del(name)
  },
};