// This code can only be imported inside client-side contexts
import { 
  initializeApp,
  getApp,
  getApps,
  type FirebaseOptions,
  type FirebaseApp
} from "firebase/app";
import { getAuth, type Auth } from 'firebase/auth'
import { getDatabase, type Database } from 'firebase/database'
import { getFirestore, initializeFirestore, type Firestore } from 'firebase/firestore'

/**
 * The Firebase config file, initiated with values saved inside local .env file. 
 */
export const firebaseConfig:FirebaseOptions = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_RTDB_URL
};

/**
 * Retrieves a Firebase client instance. If one hasn't been initialized yet, it initializes it first.
 * 
 * @param {FirebaseOptions} clientOptions - Configuration object for Firebase client.
 * @returns {FirebaseApp} Initialized Firebase client app instance.
 * @throws {Error} Throws an error if the required environment variables are missing.
 */
const initFirebaseClient = ( clientOptions: FirebaseOptions ): FirebaseApp => {

  if (!clientOptions || !clientOptions.apiKey || !clientOptions.projectId) 
    throw new Error('Required Firebase environment variables are missing.');

  let clientApp:FirebaseApp;

  if (!getApps().length) clientApp = initializeApp(clientOptions);
  else clientApp = getApp(); // Use the default app if already initialized

  return clientApp;
}

/** 
 * Initialized Firebase client instance using the provided configuration.
 * @type {FirebaseApp}
 */
export const firebase: FirebaseApp = initFirebaseClient(firebaseConfig)

/** 
 * Firebase authentication service instance associated with the initialized Firebase app.
 * @type {Auth}
 */
export const auth: Auth = getAuth(firebase)

/** 
 * Firebase Realtime Database service instance associated with the initialized Firebase app.
 * @type {Database}
 */
export const rtdb: Database = getDatabase(firebase)

/**
 * Create settings for Firestore.
 */
initializeFirestore(firebase, {
  ignoreUndefinedProperties: true
})

/**
 * Firebase Firestore service instance associated with the initialized Firebase app.
 * Local caching is disabled. Before using, users must be asked if they're on a trusted device.
 * @type {Firestore}
 */
export const firestore: Firestore = getFirestore(firebase)