import React, { useState } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import Link from 'next/link'
import { 
  motion, 
  AnimatePresence,
  type HTMLMotionProps, 
  type Transition 
} from 'framer-motion'
import { omit } from 'lodash'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { HomeIcon } from '../icons'
import { Text } from './Text'

const BreadcrumbStyles = cva('flex items-center space-x-2 ml-[2px] transition-all select-none', {
  variants: {
    crumbStyle: {
      'slash': '',
      'chevron': ''
    }
  },
  defaultVariants: {
    crumbStyle: 'chevron'
  }
})

type BreadcrumbComponentProps = 
  {
    pathSegments: Array<string>,
  }
  & VariantProps<typeof BreadcrumbStyles>
  & HTMLMotionProps<'ol'>

/**
 * `Breadcrumb` is a React functional component that renders a breadcrumb navigation UI.
 * It visually represents the path hierarchy defined by the URL's path segments and allows for navigation to any segment in the path.
 * It leverages Framer Motion for transition animations between the breadcrumb states.
 *
 * @component
 * @example
 * ```jsx
 * const pathSegments = useMemo(() => {
 *   return router.asPath.split('/').filter(Boolean);
 * }, [router]);
 *
 * <Breadcrumb pathSegments={pathSegments} crumbStyle="chevron" />
 * ```
 *
 * @param {object} props - The properties object.
 * @param {string[]} props.pathSegments - An array of strings representing each segment in the URL path.
 * @param {('chevron' | 'slash')} [props.crumbStyle='chevron'] - The style of the separators between breadcrumb items. Accepts either 'chevron' or 'slash'.
 * @param {object} [props.transition] - The transition object for framer motion to define animation settings.
 * @param {React.CSSProperties} [props.className] - Additional CSS class names to apply to the component.
 * @returns {React.Element} The rendered breadcrumb component.
 */
export const Breadcrumb:React.FC<BreadcrumbComponentProps> = ({ pathSegments, crumbStyle = 'chevron', ...props }) => {

  const defaultTransition:Transition = {
    type: 'spring',
    stiffness: 500,
    damping: 50,
  }
  
  return (
    <motion.ol
      aria-label='Breadcrumb'
      role='list'
      layout
      className={twMerge( 
        props.className, 
        BreadcrumbStyles() 
      )}
      {...omit(props, 'className', 'aria-label')}
    >

      {/* Home icon */}
      <motion.li
        transition={defaultTransition}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        layout
      >
        <Link href={'/dashboard'}>
          <HomeIcon className='h-6 w-6 flex-shrink-0 text-neutral-400 relative bottom-[2px]' layout/>
        </Link>
      </motion.li>

      {pathSegments.map((segment, idx) => {

        const pathTillSegment = `/${pathSegments.slice(0, idx + 1).join('/')}`

        return (
          <motion.li
            key={pathTillSegment}
            transition={{ 
              ...defaultTransition,
              delay: (idx + 1) * 0.35
            }}
            initial={{ opacity: 0, left: '-16px' }}
            animate={{ opacity: 1, left: '0px' }}
            exit={{ opacity: 0, left: '-16px' }}
            className='flex relative'
          >

            {crumbStyle == 'chevron' ? 
              <ChevronRightIcon className='h-6 w-6 flex-shrink-0 text-neutral-400' aria-hidden/>
            : crumbStyle == 'slash' && 
              <span className='text-neutral-500'>/</span>
            }

            <Text.Label 
              textIntent={'link'} 
              href={pathTillSegment}
              className='capitalize ml-2 text-label-lg text-neutral-500'
            >
              {segment.replace(/-/g, ' ')}
            </Text.Label>

          </motion.li>
        )
      })}

    </motion.ol>
  )
}