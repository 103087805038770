import { randexp } from 'randexp'
import { z } from 'zod'

// This file contains any custom Regex string validators used in the entire application.

/**
 * Validates a specific tenant ID, for usage in cloud infrastructure.
 * Tenant ID always needs to start with `sksc-`, and can only contain characters, 
 * numbers and `-` characters after that. 
 */
export const REGEXP_TENANT_ID = /sksc-(\w|\d|\-)+/g

/**
 * Validates a semantic application version. Example: `1.0.2-alpha1`.
 * 
 * More info can be found [here](https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string).
 */
export const REGEXP_SEMVER = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/g

/**
 * Validates an hour:minute time. Example: `12:30`
 */
export const REGEXP_HOURS_MINUTES = /([01]\d|(2)[0123]):([012345]\d)/g

/**
 * Validates an hour:minute time interval between 2 times. Example: `12:30 - 13:20`. 
 * 
 * *Caution: no guarantee that second time is later than the first time.*
 */
export const REGEXP_HOURS_MINUTES_INTERVAL = new RegExp(`${REGEXP_HOURS_MINUTES}\\s?[/-]\\s?${REGEXP_HOURS_MINUTES}`, 'g')

/**
 * Student code validators, organised by school.
 * 
 * **d'Oultremontcollege:** `[year][month][day][number]`
 */
export const REGEXP_STUDENT_CODES = {
  DOULTREMONTCOLLEGE: /^(19|20)\d\d(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])\d\d$/
}

/**
 * Regex for a password that needs to contain a minimum of 1 uppercase character,
 * 1 lowercase character, 1 special character, 1 number and a total of min. 8 characters.
 */
export const REGEXP_PASSWORD = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/g

/**
 * Regex for a valid Argon2 hash.
 * @link [Reference](https://regex101.com/r/8d0bGE/1)
 */
export const REGEXP_ARGON2_HASH = /^\$argon2id\$v=(?:16|19)\$m=\d{1,10},t=\d{1,10},p=\d{1,3}(?:,keyid=[A-Za-z0-9+/]{0,11}(?:,data=[A-Za-z0-9+/]{0,43})?)?\$[A-Za-z0-9+/]{11,64}\$[A-Za-z0-9+/]{16,86}$/i

/**
 * Regex for a domain that can be used inside an email. Accepts subdomains and 
 * needs the string to start with an @ symbol.
 */
export const REGEXP_EMAIL_DOMAIN = /^@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/g

/**
 * Regex for an authentication code that consists of exactly 8 numbers.
 */
export const REGEXP_AUTH_CODE_8_DIGITS = /(\d{8})/g 

/**
 * This object provides utilities for handling and validating lesson invite codes.
 *
 * @property {RegExp} regexp - A regular expression for matching a valid format of a lesson invite code.
 *
 * @property {function(): string} get - A function to generate a valid lesson invite code.
 *   It uses the regular expression defined in the `regexp` property to generate a code, and verifies its validity using the `isValid` function.
 *   It keeps generating a new code until a valid one is found, then returns the valid code.
 *   @returns {string} - A valid lesson invite code.
 *
 * @property {function(string): boolean} isValid - A function to validate a lesson invite code.
 *   It checks if a code string adheres to the following rules:
 *   - The code must have exactly 3 uppercase letters.
 *   - The code must have exactly 3 digits.
 *   - No character should repeat more than once.
 *   @param {string} code - The lesson invite code to validate.
 *   @returns {boolean} - Returns true if the code is valid, false otherwise.
 *
 * @example
 * ```typescript
 * const LessonInviteCodeSchema = z.string()
 *   .regex(LESSON_INVITE_CODE.regexp, "Invalid format for invite code.")
 *   .superRefine(
 *     code => LESSON_INVITE_CODE.isValid(code),
 *     "Invalid lesson invite code."
 *   );
 *
 * // Usage:
 * const parsed = LessonInviteCodeSchema.safeParse("ABC123");
 * if (!parsed.success) {
 *   console.error(parsed.error.issues);
 * }
 *
 * // Generating a valid invite code:
 * const validInviteCode = LESSON_INVITE_CODE.get();
 * console.log(validInviteCode);  // Outputs a valid invite code e.g., "D4X00F"
 * ```
 */
export const LESSON_INVITE_CODE = {
  /**
   * A regular expression for matching a valid format of a lesson invite code.
   * @type {RegExp}
   */
  regexp: /([A-Z\d]{6})/s,

  /**
   * Generates a valid lesson invite code by enforcing the rules defined in the `isValid` function.
   * It utilizes a while loop to continue generating codes using the `randexp` function until a valid code,
   * adhering to the rules set in the `isValid` function, is generated.
   * 
   * @returns {string} - A valid lesson invite code adhering to the specified format and rules.
   */
  get: function(): string {
    let isValid = false;
    let inviteCode = '';

    while (!isValid) {
      // Named function so we can access the `this` keyword.
      inviteCode = randexp(this.regexp);
      isValid = this.isValid(inviteCode);
    }

    return inviteCode;
  },

  /**
   * Validates a lesson invite code by enforcing the following rules:
   * - The code must have exactly 3 uppercase letters.
   * - The code must have exactly 3 digits.
   * - No character should repeat more than once.
   * 
   * @param {string} code - The lesson invite code to validate.
   * @returns {boolean} - Returns true if the code is valid, false otherwise.
   */
  isValid: (code: string): boolean => {
    let charCount = 0;
    let numCount = 0;
    const seenChars = new Set<string>();

    for (const char of code) {
      if (seenChars.has(char)) continue;  // Ignore repeated characters
      seenChars.add(char);

      if (/[A-Z]/.test(char)) {
        charCount += 1;
      } else if (/[0-9]/.test(char)) {
        numCount += 1;
      }
      
      if (charCount > 3 || numCount > 3) return false;  // Early exit if counts exceed 3
    }

    return charCount === 3 && numCount === 3;
  }
}

/**
 * Regular expression for validating safe URL paths.
 *
 * Rules:
 * - Begins with a `/`.
 * - Does not have consecutive slashes (`//`).
 * - Does not end with a `/`.
 * - Allows a maximum depth of 10 subpaths.
 * - Alphanumeric characters: A-Z, a-z, 0-9 are allowed.
 * - Special characters allowed: -, _, ., ~
 * - General delimiters allowed: :, /, ?, #, [, ], @
 * - Sub-delimiters allowed: !, $, &, ', (, ), *, +, ,, ;, =
 * - In the query string:
 *   - Supports `key`, `key=value`, `key[subkey]`, and `key[subkey]=value` patterns.
 *   - Only strings and numbers are permitted as keys and values.
 *   - Keys can optionally have subkeys enclosed in square brackets `[]`.
 *   - The equal sign `=` is optional.
 *   - Multiple query parameters should be separated with the `&` character.
 */
export const REGEXP_URL_PATH = /^\/(?!\/)([A-Za-z0-9\-_.~:!$&'()*+,;=:@#\/\[\]]*\/?){1,10}[^\/](\?[A-Za-z0-9\[\]]+([A-Za-z0-9\[\]]+)?(=[A-Za-z0-9]+)?(&[A-Za-z0-9\[\]]+([A-Za-z0-9\[\]]+)?(=[A-Za-z0-9]+)?)*)?$/s

/**
 * Regular expression for validating ISO8601 datetime strings, without an included timezone.
 * 
 * - Minimum date: `1970-01-01T00:00:00.000Z`
 * - Maximum date: `2999-12-31T23:59:59.999Z`
 */
export const REGEXP_ISO_8601_NO_TIMEZONE = /(19(7|8|9)\d|2\d{3})\-(0[1-9]|1[0-2])\-(0[1-9]|1[0-9]|2[0-9]|3[0-1])T(0[0-9]|1[0-9]|2[0-3])\:([0-5]{1}[0-9]{1})\:([0-5]{1}[0-9]{1})\.([0-9]{3})Z/g