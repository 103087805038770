import React, { useState } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid'
import { 
  CheckBadgeIcon, 
  ChatBubbleBottomCenterTextIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline'
import { 
  ToastContainer, 
  toast as ToastifyToast, 
  type ToastContent, 
  type ToastOptions 
} from 'react-toastify'

export const toastStyles = cva('!rounded-xl !shadow-xl !p-3', {
  variants: {
    toastColor: {
      info: '!bg-white border-l-[6px] border-primary-lighter !text-neutral-600 ring-1 ring-inset ring-neutral-100',
      success: '!bg-gradient-to-b from-primary-dark to-primary-light !text-white',
      warning: '!bg-gradient-to-b from-orange-800 to-orange-500 !text-white',
      error: '!bg-gradient-to-b from-red-900 to-amber-700	!text-white',
      default: '!bg-white border-l-[6px] border-primary-lighter  !text-neutral-600 ring-1 ring-inset ring-neutral-100'
    }
  },
  defaultVariants: {
    toastColor: 'default'
  }
})

/**
 * `ToastProvider` is a functional component that renders a toast notification container with specified properties.
 * This component acts as a style wrapper around `react-toastify`'s `ToastContainer`, enforcing particular styles and behaviors for toast notifications.
 *
 * @component
 * @example
 * // Usage (inside _app.tsx)
 * <ToastProvider />
 * 
 * @returns {React.FC}
 */
export const ToastProvider:React.FC = () => 
  <ToastContainer 
    limit={4} 
    autoClose={2500} 
    position='top-center'
    toastClassName={(props) => twMerge(props?.defaultClassName, toastStyles({ toastColor: props?.type || 'default'}))}
    bodyClassName='!font-mundial !font-light !py-0 !my-0'
    pauseOnHover={false}
    pauseOnFocusLoss={false}
  />

/**
 * `toast` is a utility function to trigger toast notifications with specified content and options.
 * This function acts as a style wrapper around `react-toastify`'s `toast` function, enforcing particular styles and behaviors for toast icons based on the type of toast.
 *
 * @param {React.ReactNode | string} content - The content to be displayed in the toast notification.
 * @param {Omit<ToastOptions, 'icon'>} options - The configuration options for the toast notification, excluding the 'icon' property.
 *
 * @example
 * // Usage
 * toast('Hello World', { type: 'success' });
 */
export const toast = (content: ToastContent, options: Omit<ToastOptions, 'icon'>) => ToastifyToast(content, {
  ...options,
  icon: ({ type }) => {
    switch(type) {
      case 'default': return <InformationCircleIcon className='inline-block !h-6 !w-6 bottom-[1px] relative text-dark overflow-visible'/>
      case 'info': return <InformationCircleIcon className='inline-block !h-6 !w-6 bottom-[1px] relative text-dark overflow-visible'/>
      case 'success': return <CheckBadgeIcon className='inline-block !h-6 !w-6 bottom-[1px] relative text-accent overflow-visible'/>
      case 'warning': return <ExclamationTriangleIcon className='inline-block !h-6 !w-6 relative text-amber-500 overflow-visible'/>
      case 'error': return <ExclamationCircleIcon className='inline-block !h-6 !w-6 bottom-[1px] relative text-red-500 overflow-visible'/>
    }
  }
})
