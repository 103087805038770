import React, { useState } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import { 
  motion, 
  AnimatePresence,
  type HTMLMotionProps, 
  type Transition 
} from 'framer-motion'
import { omit } from 'lodash'
import { 
  ChevronDoubleRightIcon,
  InformationCircleIcon,
  type IconType 
} from '../icons'
 
/**
 * 
 * Button documentatie:
 * 
 * Er kunnen verschillende states naar de button doorgestuurd worden. De button kan van size
 * veranderen d.m.v. de HeadlessUI transition component. Deze gaat van groot naar klein.
 * 
 * FRAMER MOTION IMPLEMENTEREN
 * Layout prop zorgt ervoor dat WAT VOOR CHANGE dan ook meegenomen wordt in een vloeiende animatie.
 *  - Layout kan per component in (variations) opgenomen worden voor Framer, wellicht te combineren met CVA?
 *  - Kijken hoe je een React ForwardRef props kan meegeven van de originele FC die daarin in wordt gepakt.
 * 
 * HEROICONS / SVG'S IMPORTEREN ALS KLEIN PLAATJE VOOR BUTTON-SMALL
 * Kijken of er generieke types beschikbaar zijn in de @heroicons/react library
 */

const ButtonStyles = cva('border-2 rounded-[8px] cursor-pointer inline-flex flex-row items-center transition-colors duration-150 text-sm sm:text-base !leading-5 outline-none', {
  variants: {
    btnSize: {
      'large': 'w-48 h-12 border-2 py-2 px-3',
      'small': 'p-1 h-10 w-10 aspect-square justify-center',
    },
    btnStyle: {
      'primary': 'border-primary focus:border-[3px] bg-transparent text-dark hover:text-primary',
      'primary-filled': 'border-primary-dark focus:border-primary-lighter bg-primary text-white hover:bg-primary-darker',
      'nav': 'border-primary-lighter text-white hover:bg-primary',
    },
    btnState: {
      'default': '',
      'active': 'bg-gradient-to-br from-transparent to-primary-light',
      'disabled': '!pointer-events-none opacity-80'
    }
  },
  defaultVariants: {
    btnSize: 'large',
    btnStyle: 'primary-filled',
    btnState: 'default'
  }
})

export type ButtonComponentProps = 
  {
    children?: React.ReactNode,
    disabled?: boolean,
    expandBeforeUse?: boolean,
    icon?: React.FC<IconType>,
    iconColor?: "primary" | "primary-lighter" | "primary-light" | "primary-dark" | "primary-darker" | "white" | "dark" | null | undefined
  } 
  & VariantProps<typeof ButtonStyles> 
  & HTMLMotionProps<'button'>

/**
 * The default Button to be used inside the Skip Scanner applications. Based on Uber Base
 * guidelines. 
 * 
 * Supports 2 states: `large` and `small`. Full text and an icon can be
 * showed when button is in `large` mode. When in `small` mode, only an icon can be rendered.
 * 
 * A default icon is rendered if no icon is provided. When text is not provided, the provided icon
 * will be rendered, or else the default icon.
 * 
 * @returns {React.FC}
 */
export const Button:React.FC<ButtonComponentProps> = ({ btnSize = 'large', iconColor = 'white', btnState, btnStyle, expandBeforeUse = false, ...props }) => {

  const [ isHovering, setIsHovering ] = useState<boolean>(false)
  const [ isActive, setIsActive ] = useState<boolean>(false)

  const defaultTransition:Transition = {
    type: 'spring',
    stiffness: 500,
    damping: 50,
  }
  const IconComponent = props.icon

  return (
    <motion.button
      layout
      transition={defaultTransition}
      className={twMerge( 
        props.className, 
        `group transition-transform duration-150 leading-5 ${(!props.children || !props.icon) && 'justify-center'}`,
        ButtonStyles({ btnSize, btnState, btnStyle }) 
      )}
      onHoverStart={() => setIsHovering(true)}
      onHoverEnd={() => setIsHovering(false)}
      onMouseDown={() => setIsActive(true)}
      onMouseUp={() => setIsActive(false)}

      aria-expanded={btnSize == 'large'}
      aria-disabled={btnState == 'disabled'}
      {...omit(props, 'className', 'icon')}
    >

      {/* Logo */}
      { IconComponent ? (

        // Render the icon when found
        <IconComponent 
          layout
          aria-disabled={btnState == 'disabled'}
          className={`${btnSize == 'large' && props.children ? 'mr-2' : ''} ${btnStyle == 'nav' ? 'w-5' : 'w-6'}`}
          iconColor={iconColor}
          animate={{ 
            scale: 
              (isActive && btnState !== 'disabled' && !props.children) ? 0.9 
              : (isHovering && btnState !== 'disabled' && !props.children) ? 1.1 
              : 1
          }}
        /> 
        )

        // When button size is small and only an icon can be displayed, show a chevronDoubleRight to indicate an action
        : btnSize == 'small' ? (
          <AnimatePresence>
            <ChevronDoubleRightIcon 
              aria-disabled={btnState == 'disabled'}
              className='w-4'
              iconColor={iconColor}
              initial={{ opacity: 0, rotate: 180 }}
              animate={{ 
                opacity: 1, 
                rotate: 0,
                scale: 
                  (isActive && btnState !== 'disabled' && !props.children) ? 0.95 
                  : (isHovering && btnState !== 'disabled' && !props.children) ? 1.05 
                  : 1,
              }} 
              exit={{ opacity: 0, rotate: 180 }}
            />
          </AnimatePresence>
        )

        // Display an Information circle when no text or icon is provided
        : (btnSize == 'large' && !props.children) && (
          <AnimatePresence>
            <InformationCircleIcon 
              aria-disabled={btnState == 'disabled'}
              className='w-6'
              iconColor={iconColor}
              initial={{ opacity: 0, rotate: 180 }}
              animate={{ 
                opacity: 1, 
                rotate: 0,
                scale: 
                  (isActive && btnState !== 'disabled' && !props.children) ? 0.95 
                  : (isHovering && btnState !== 'disabled' && !props.children) ? 1.05 
                  : 1,
              }} 
              exit={{ opacity: 0, rotate: 180 }}
            />
          </AnimatePresence>
        )
      }

      {/* Main text */}
      <AnimatePresence>
        {btnSize == 'large' && (

          <motion.span 
            layout
            initial={{ opacity: 0, x: -50 }}
            animate={{ 
              opacity: 1, 
              x: 0,
              marginLeft: (isHovering && btnState !== 'disabled' && props.icon && props.children) ? '0.375rem': '0',
              scale: 
                (btnState !== 'disabled' && isActive) ? 0.95 
                : (btnState !== 'disabled' && isHovering) ? 1.05 
                : 1
            }}
            exit={{ opacity: 0, x: -25, scale: 0.45 }}
          >
            {props.children}
          </motion.span>

        )}
      </AnimatePresence>

    </motion.button>
  )
}