import React, { useState } from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid'
import { twMerge } from 'tailwind-merge'
import { 
  motion, 
  AnimatePresence,
  type HTMLMotionProps, 
  type Transition 
} from 'framer-motion'

const TooltipStyles = cva('', {
  variants: {
    tooltipStyle: {
      'primary': 'bg-primary-dark text-neutral-100',
      'gray': 'bg-neutral-200 text-primary-darker'
    }
  },
  defaultVariants: {
    tooltipStyle: 'primary'
  }
})

type TooltipComponentProps = 
  {
    tooltipText: React.ReactNode
  }
  & VariantProps<typeof TooltipStyles>
  & HTMLMotionProps<'div'>


/**
 * Displays a custom styled tooltip on hover over a QuestionMarkCircleIcon.
 * 
 * @param {string} tooltipText - The text to display within the tooltip.
 * @param {("primary" | "gray")} tooltipStyle - The style variant for the tooltip. Defaults to 'primary'.
 * @param {HTMLMotionProps<'div'>} props - Any additional props to pass to the underlying motion.div element.
 * 
 * @example
 * <Tooltip tooltipText="This is a tooltip" tooltipStyle="gray" />
 */
export const Tooltip:React.FC<TooltipComponentProps> = ({ tooltipStyle, tooltipText, ...props }) => {

  const [ isHovering, setIsHovering ] = useState<boolean>(false)

  return (
    <motion.div 
      className={twMerge(
        props.className,
        "group inline relative"
      )}
      onHoverStart={() => setIsHovering(true)}
      onHoverEnd={() => setIsHovering(false)}
    >
      <QuestionMarkCircleIcon 
        className="h-4 w-4 relative inline-block text-primary-darker cursor-help peer"
      />
      
      <AnimatePresence>
        {isHovering && (

          <motion.span
            className={twMerge(
              'absolute text-sm select-none font-light py-1 px-2 rounded-md z-50 min-w-max block',
              TooltipStyles({ tooltipStyle })
            )}
            initial={{ opacity: 0, bottom: '12px', left: '16px' }}
            animate={{ opacity: 100, bottom: '16px', left: '18px' }}
            exit={{ opacity: 0, bottom: '12px', left: '16px' }}
            role='tooltip'
          >
            {tooltipText}
          </motion.span>

        )}
      </AnimatePresence>

    </motion.div>
  )
}