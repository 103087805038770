import React from 'react'
import { motion, HTMLMotionProps } from 'framer-motion'
import { omit } from 'lodash'
import { cva, VariantProps } from 'class-variance-authority'
import twMerge from '../tailwind-merge.config'
import { EyeIcon, IconType } from '../icons'

const LinebreakStyles = cva('col-span-2 grid grid-cols-7 items-center', {
  variants: {
    linebreakColor: {
      'dark': 'text-dark',
      'light': 'text-light',
      'primary-darker': 'text-primary-darker',
      'primary-dark': 'text-primary-dark',
      'primary': 'text-primary',
      'primary-light': 'text-primary-light',
      'primary-lighter': 'text-primary-lighter',
      'white': 'text-white ',
      'accent': 'text-accent',

      'gradient-to-b': 'text-transparent bg-clip-text bg-gradient-to-b',
      'gradient-to-r': 'text-transparent bg-clip-text bg-gradient-to-r',
      'gradient-to-br': 'text-transparent bg-clip-text bg-gradient-to-br',
      'gradient-to-tr': 'text-transparent bg-clip-text bg-gradient-to-tr'
    },
    defaultIconColor: {
      'primary-darker': '!text-primary-darker',
      'primary-dark': '!text-primary-dark',
      'primary': '!text-primary',
      'primary-light': '!text-primary-light',
      'primary-lighter': '!text-primary-lighter',
      'neutral-400': '!text-neutral-500'
    },
    lineBreakMargin: {
      sm: 'my-8 sm:my-12 lg:my-16',
      md: 'my-12 sm:my-16 lg:my-20',
      lg: 'my-16 sm:my-20 lg:my-24',
      none: ''
    }
  },
  defaultVariants: {
    linebreakColor: 'light',
    defaultIconColor: 'neutral-400',
    lineBreakMargin: 'none'
  }
})

type LinebreakProps = {
  children?: React.ReactNode,
} & Omit<HTMLMotionProps<'div'>, 'children'> & VariantProps<typeof LinebreakStyles>

export const Linebreak:React.FC<LinebreakProps> = ({ children, className, lineBreakMargin, linebreakColor, defaultIconColor, ...props }) => {
 
  return (
    <motion.div
      className={twMerge( className, LinebreakStyles({ lineBreakMargin, linebreakColor }) )}
      {...omit(props, 'className', 'children')}
    >
      <span className="col-span-3 border border-neutral-200 h-0 rounded-full"></span>
      <div className="col-span-1 h-0 inline-flex relative bottom-[0.65rem] justify-center">

        {children ? 
          children
        : 
          <EyeIcon className={twMerge('h-5 w-5 inline-block', LinebreakStyles({ defaultIconColor }))}/>
        }

      </div>
      <span className="col-span-3 border border-neutral-200 h-0 rounded-full"></span>
    </motion.div>
  )
}
