import React, { useState } from "react";
import { NextPage } from "next";
import { useAsync } from "react-use";
import { useRouter } from "next/router";
import { trpx } from "lib/trpc";
import { env } from "lib/env.mjs";
import { useIsClient } from "usehooks-ts";
import { TRPCError } from "@trpc/server";

import type { GenericCallback } from "@skip-scanner/toolkit/types/util";
import { 
  type FeatureFlagKey, 
  type FeatureFlag, 
  type TenantConfig,
  type Modules,
  ModuleSchema 
} from "./types";

//#region Feature flag utils

  /**
   * Checks if a feature flag is enabled.
   * @param keyOrFlag - The key or flag to check.
   * @returns A promise that resolves to a boolean indicating if the feature flag is enabled.
   */
  export const isFeatureEnabled = async (keyOrFlag: FeatureFlagKey | FeatureFlag): Promise<boolean> => {
    
    if (typeof keyOrFlag === 'string') {
      const flags = await getFeatureFlags();
      return flags[keyOrFlag]?.enabled == true;
    } 

    return keyOrFlag !== null && keyOrFlag?.enabled == true;
  }

  /**
   * Retrieves the feature flags from the server.
   * @returns A promise that resolves to a record of feature flag keys and their corresponding values.
   */
  export const getFeatureFlags = async (): Promise<Record<FeatureFlagKey, FeatureFlag>> => {
    const config = await getTenantConfig();
    return config.featureFlags;
  }

  /**
   * Custom hook that retrieves the feature flags from the server.
   * @returns A record of feature flag keys and their corresponding values.
   */
  export const useFeatureFlags = () => {
    
    const [flags, setFlags] = useState<Record<FeatureFlagKey, FeatureFlag> | null>(null)

    useAsync(async () => {
      const flags = await getFeatureFlags()
      setFlags(flags)
    }, [])

    return flags
  }

  /**
   * Executes a function with a fallback value. 
   * First, the key gets checked if it is enabled and correctly configured.
   * If it does, the function will be executed and the result will be returned.
   * If it does not, the fallback value will be returned.
   * @param key - The key used for the execution.
   * @param fallback - The fallback value to use if the execution fails.
   */
  export async function executeWithFallback<T = any>(
    key: FeatureFlagKey, 
    fallback: GenericCallback<T>
  ): Promise<T | void> {

    const tenantID = env.NEXT_PUBLIC_TENANT_ID.replaceAll('sksc-', '');

    const features = await getFeatureFlags();
    const feature = features[key];

    if (feature?.impl_type == 'component') {
      throw new Error(`Feature ${key} is a component feature. Use the "WithFallback" component instead.`);
    }

    if(feature?.impl_type == 'page') {
      throw new Error(`Feature ${key} is a page feature. Use the "useImportedPage" hook instead.`);
    }

    if (!feature || !await isFeatureEnabled(feature)) {
      return fallback()
    }

    const module = await import(`config/${tenantID}/${feature.impl_path}`);
    return module.default();
  }

  type WithFallbackProps = {
    featureKey: FeatureFlagKey,
    children?: React.ReactNode,
    featureComponentProps?: any
  }

  /**
   * Higher-order component that renders a fallback component if a feature is not enabled,
   * otherwise renders the imported component.
   *
   * @param featureKey - The key of the feature to check.
   * @param children - The fallback component to render if the feature is not enabled.
   * @returns The rendered component.
   */
  export const WithFallback: React.FC<WithFallbackProps> = ({ featureKey: key, children, featureComponentProps }) => {

    const [modulePath, setModulePath] = useState<string | null>(null);

    useAsync(async () => {

      const tenantID = env.NEXT_PUBLIC_TENANT_ID.replaceAll('sksc-', '');

      const features = await getFeatureFlags();
      const feature = features[key];
    
      if (feature?.impl_type == 'function') {
        throw new Error(`Feature ${key} is a function feature. Use the "executeWithFallback" function instead.`);
      }
      if(feature?.impl_type == 'page') {
        throw new Error(`Feature ${key} is a page feature. Use the "useImportedPage" hook instead.`);
      }

      if (!feature || !await isFeatureEnabled(feature)) {
        setModulePath(null);
        return;
      }

      setModulePath(`${tenantID}/${feature.impl_path}`);
    }, [key])

    if (modulePath) {
      const ImportedComponent = React.lazy(() => import(`config/${modulePath}`));
      return (
        <React.Suspense fallback={''}>
          <ImportedComponent {...featureComponentProps}/>
        </React.Suspense>
      );
    }

    return <>{children}</>;
  }

  /**
   * Custom hook that dynamically imports a page component based on the provided implementation path.
   * Feature checks need to be done inside `getServerSideProps` before this hook is used.
   * @param impl_path - The implementation path of the page component.
   * @returns The imported page component or null if the client is not available.
   * 
   * @example
   * ```tsx
   * export const getServerSideProps: GetServerSideProps = async ({ req }) => {
   *  
   *   const features = await getFeatureFlags();
   *   const feature = features['oauth.microsoft-azure-ad'];
   *
   *   if (!feature || !feature.enabled || feature.impl_type !== 'page') {
   *     return {
   *       redirect: {
   *         destination: '/',
   *         permanent: false,
   *       },
   *     };
   *   }
   *
   *   return {
   *     props: {
   *       featureImplPath: feature.impl_path,
   *     },
   *   };
   * }
   *
   * const FeatureTest2: NextPage<{ featureImplPath: string }> = ({ featureImplPath }) => {
   *
   *   const ImportedPage = useImportedPage(featureImplPath);
   *
   *   return (
   *     <>{ImportedPage}</>
   *   )
   * }
   *
   * export default FeatureTest2;
   * ```
   */
  export const useImportedPage = (impl_path: string) => {
    
    const isClient = useIsClient()
    
    const router = useRouter();
    const [page, setPage] = useState<NextPage | null>(null);
    
    useAsync(async () => {
        
      const tenantID = env.NEXT_PUBLIC_TENANT_ID.replaceAll('sksc-', '');
      const modulePath = `${tenantID}/${impl_path}`;

      try {
        const importedModule = await import(`config/${modulePath}`);
        setPage(importedModule.default);
      }
      catch (err) {
        console.error(err)
        router.push('/')
      }

    }, [impl_path])
    
    if(!isClient) return null
    else return page;
  }

//#endregion

//#region Module utils

  type GetModuleValueProps = <K extends keyof Modules>(module: K) => Promise<Modules[K]>;

  /**
   * Retrieves the value of a module from the tenant's config.
   * @param module - The module key.
   * @returns The value of the module.
   * @throws Error if the module is not found in the ModuleSchema.
   */
  export const getModuleValue: GetModuleValueProps = async (module) => {
    const config = await getTenantConfig();

    // Check if the module key exists in the tenant's config and return its value
    if (config.modules && config.modules[module] !== undefined) {
      return config.modules[module] as Modules[typeof module];
    }

    // If the module key doesn't exist, return the default value from ModuleSchema
    const defaultModuleSchema = ModuleSchema.shape[module] as any;
    if (defaultModuleSchema) {
      return defaultModuleSchema._def.defaultValue as Modules[typeof module];
    }

    // Handle the case where the module is not defined in the schema
    throw new Error(`Module '${module}' not found in ModuleSchema`);
  };

//#endregion

//#region Tenant config utils

  /**
   * Retrieves the tenant configuration from the server.
   * @returns {TenantConfig} The tenant configuration.
   */
  export const getTenantConfig = async (): Promise<TenantConfig> => {

    if(typeof window === 'undefined') {
      const tenantID = env.NEXT_PUBLIC_TENANT_ID.replaceAll('sksc-', '')

      try {
        const tenantConfig = await import(`config/${tenantID}/index.ts`).then((module) => {
          
          if(!module.default) throw new TRPCError({
            code: 'INTERNAL_SERVER_ERROR',
            message: `The default configuration values for tenant "${tenantID}" could not be found.`
          })
  
          return module.default
        }) as TenantConfig
  
        return tenantConfig
      }
      catch(err) {
        throw new TRPCError({
          code: 'INTERNAL_SERVER_ERROR',
          message: `Could not fetch configuration for tenant "${tenantID}"`
        })
      }
    }

    const config = await trpx.tenant.getTenantConfig.query({})
    return config
  }

  /**
   * Gets called inside the tenants `TENANT_ID/index.ts` file to check if the tenant configuration is properly configured.
   * Throws an error if a flag is enabled but does not have a proper corresponding implementation.
   * Also throws an error if an undefined module is used.
   * 
   * @param {Record<FeatureFlagKey, FeatureFlag>} featureFlags - The feature flags to check.
   * @throws {Error} - If a feature is enabled but not properly configured.
   */
  export const checkTenantConfig = (config:TenantConfig) => {

    // ########################
    // # 1. Check feature flags
    // ########################

    Object.keys(config.featureFlags).forEach(async (key) => {

      const feature = config.featureFlags[key as FeatureFlagKey];

      if(feature == null) return;

      const tenantID = env.NEXT_PUBLIC_TENANT_ID.replaceAll('sksc-', '');

      // Check the implementation path
      const module = await import(`config/${tenantID}/${feature.impl_path}`).catch(err => {
        throw new Error(`Could not import feature ${tenantID}/${feature.impl_path}`)
      });

      if(!module.meta || module.meta.type !== feature.impl_type) {
        throw new Error(`No correct meta info found for feature ${tenantID}/${feature.impl_path}`)
      }

      if(!module.default) {
        throw new Error(`No default export found for feature ${tenantID}/${feature.impl_path}`)
      }

    });

    // ########################
    // 2. Check module values
    // ########################
    
    const parsedModules = ModuleSchema.safeParse(config.modules);
    if(!parsedModules.success) {
      throw new Error(`Invalid module configuration: ${parsedModules.error}`)
    }

  }

//#endregion