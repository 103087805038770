import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware'
import { getSession } from 'next-auth/react'
import { Notification } from '@skip-scanner/toolkit/database'
import { toast } from '@skip-scanner/ui'
import { trpx } from 'lib/trpc'
import { indexedDBStorage } from './index'

import { firestore } from '@skip-scanner/toolkit/firebase/client'
import { 
  doc, 
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  getDoc,
  writeBatch,
  updateDoc,
  deleteDoc,
  arrayUnion,
  type DocumentReference, 
  type DocumentData,
  type Query 
} from 'firebase/firestore'

type FilterState = {
  records: '25' | '50' | '100',
  page: number,
  amountPages: number,
  sort: 'archived_first' | 'new_first' | 'default'
}

type NotificationState = {

  notifications: Array<Notification>,
  filteredNotifications: Array<Notification>,
  filterData: FilterState,

  fetchAll: (silent?: boolean) => Promise<void>,
  filter: (filters?: Partial<Omit<FilterState, 'amountPages'>> ) => void,
  updateOne: (
    userID: string,
    notifID: string,
    notificationUpdates: Partial<Pick<Notification, 'title' | 'message' | 'archived'>>,
    silent?: boolean
  ) => Promise<void>,
  deleteOne: (
    userID: string,
    notifID: string,
    silent?: boolean
  ) => Promise<void>

  sendNotification: (
    contents: {
      title: string, 
      message: string, 
      href:string
    },
    receiver?: string,
    silent?: boolean
  ) => Promise<void>
}

export const useNotificationStore = create<NotificationState>()(
  persist(
    (set, get) => ({

      notifications: [],
      filteredNotifications: [],
      filterData: {
        records: '25',
        page: 1,
        amountPages: 1,
        sort: 'default'
      },

      /**
       * Makes a client-side query call to Firestore to fetch all notifications for the currently
       * logged in user. To be called periodically.
       */
      fetchAll: async (silent = true) => {

        const { filter } = get()
        
        const session = await getSession()
        if(!session) return

        const notificationsRef = collection(firestore, 'employees', session.user.email, 'notifications')
        const notificationsQuery = query(
          notificationsRef,
          orderBy('created', 'desc')
        ) as Query<Notification, DocumentData>

        const snapshot = await getDocs(notificationsQuery)

        if(!silent) toast(`${snapshot.docs.length} notificaties opgehaald.`, { type: 'info'})
        
        set({ notifications: snapshot.docs.map(doc => doc.data()) })

        // Filters all the notifications, based on the current filters applied in the state
        filter({
          page: 1,
          records: '25',
          sort: 'default'
        })
      },
      
      /**
       * Filters the notifications, based on the filter info the user provides in the UI. 
       */
      filter: (filters = {}) => {
        set((state) => {
          
          const newFilters = { ...state.filterData, ...filters };

          // Calculate amount of pages
          const totalNotifications = state.notifications.length;
          const recordsPerPage = parseInt(newFilters.records, 10);
          newFilters.amountPages = Math.ceil(totalNotifications / recordsPerPage);
          
          // Ensure the page number is within the valid range
          if(newFilters.amountPages < 1) newFilters.amountPages = 1;
          newFilters.page = newFilters.page > newFilters.amountPages ? newFilters.amountPages : newFilters.page;
          newFilters.page = newFilters.page < 1 ? 1 : newFilters.page;

          // Apply filters
          const sortedNotifications = [...state.notifications].sort((a, b) => {
            switch (newFilters.sort) {
              case 'archived_first':
                return (a.archived === b.archived) ? 0 : a.archived ? -1 : 1;
              case 'new_first':
                return (a.archived === b.archived) ? 0 : a.archived ? 1 : -1;
              default:
                // Assuming 'default' is to sort by creation date, newest first.
                // Adjust the logic here if it's different.
                return b.created.localeCompare(a.created);
            }
          });

          // Apply pagination
          const startIndex = (newFilters.page - 1) * recordsPerPage;
          const paginatedNotifications = sortedNotifications.slice(startIndex, startIndex + recordsPerPage);

          return {
            filterData: newFilters,
            filteredNotifications: paginatedNotifications,
          };

        })
      },

      updateOne: async ( userID, notifID, notificationUpdates, silent = true ) => {

        const notificationsCopy = [...get().notifications];
        const notifIndex = notificationsCopy.findIndex((notif) => notif.id === notifID);
        
        if (notifIndex !== -1) {
          notificationsCopy[notifIndex] = { ...notificationsCopy[notifIndex], ...notificationUpdates };
          set({ notifications: notificationsCopy });
        }

        const notifRef = doc(firestore, 'employees', userID, 'notifications', notifID)
        const notifSnap = await getDoc(notifRef)

        if(!notifSnap.exists()) return

        try {
          await updateDoc(notifRef, { ...notificationUpdates })
        }  
        catch (err) { 
          console.warn(`Warning: could not update notification ${notifID}`, err) 
        }          

        if (!silent) toast('Notification updated successfully', { type: 'success' });
        
        get().filter()
      },

      deleteOne: async ( userID, notifID, silent = true ) => {

        const { notifications, filter } = get();
        
        const notifIndex = notifications.findIndex((notif) => notif.id === notifID);
        
        if (notifIndex !== -1) {
          // Use splice to remove the item from the array
          const notificationsCopy = [...notifications];
          notificationsCopy.splice(notifIndex, 1);
          set({ notifications: notificationsCopy });
        }
      
        const notifRef = doc(firestore, 'employees', userID, 'notifications', notifID);
        const notifSnap = await getDoc(notifRef);
      
        if(!notifSnap.exists()) return;
      
        try {
          await deleteDoc(notifRef);
        } catch (err) { 
          console.warn(`Warning: could not delete notification ${notifID}`, err);
        }
      
        if (!silent) toast('Notification deleted successfully', { type: 'success' });
        
        filter();
      },

      /**
       * Sends a notification to a specified person. If no receiver is set, the account from where the notification 
       * is sent, will also be the receiver. This can be used to automate notifications on certain client-side actions.
       * 
       * A call to the server is made, because the amount of notifications needs to be checked (can't exceed 500 in the database).
       * 
       * **Warning:** errors are handled on the place of function calling.
       */
      sendNotification: async (contents, receiver, silent = true) => {
        
        await trpx.notification.sendNotification.mutate({ contents, receiver })
        if(!silent) toast(`Melding verstuurd naar ${receiver}`, { type: 'info'})
      }

    }),
    {
      name: 'zustand:notification-storage',
      storage: createJSONStorage(() => 
        sessionStorage
      )
    }
  )
)