import React, { useCallback, useEffect } from "react";
import { useAppointmentStore, useTenantStore } from "lib/stores";
import type { ProviderProps } from "lib/providers";
import { toast } from "@skip-scanner/ui";
import { REGEXP_STUDENT_CODES } from "@skip-scanner/toolkit/lib/validators";
import moment from "moment";
import BarcodeReader from "react-barcode-reader";

import type { ScanSummary } from "@skip-scanner/toolkit/database/models/registration";
import { useIsClient } from "usehooks-ts";
import { useRouter } from "next/router";
import path from "path";
import { useAsync } from "react-use";

/**
 * Listens to barcode scans and updates the cached `unresolvedScans` state.
 * The lesson page listens to changes in this unresolvedScans state and updates the active lesson accordingly. 
 * 
 * @returns {JSX.Element} The children wrapped within the provider.
 */
const BarcodeScanProvider:React.FC<ProviderProps> = ({ children }) => {

  const { push } = useAppointmentStore(state => state.unresolvedScans)
  const { license } = useTenantStore()

  const handleBarcodeScan = useCallback((barcodeInput: string) => {

    if(license?.hardwareType !== 'barcode') {
      toast('U heeft een gebruikerslicentie voor de Cloud Scan oplossing. U kunt geen gebruik maken van een barcodescanner.', { type: 'warning' })
      return
    }
    
    if(!REGEXP_STUDENT_CODES.DOULTREMONTCOLLEGE.test(barcodeInput)) return

    const scan:ScanSummary = {
      studentID: barcodeInput,
      time: moment().milliseconds(0).toISOString()
    }

    // console.log(`[BarcodeScanProvider] Received scan: ${JSON.stringify(scan)}`)
    
    // Add the scan to the unresolved scans
    push(scan)
  }, [])

  return (
    <>  
      <BarcodeReader onScan={handleBarcodeScan}/>
      {children}
    </>
  )
}

/**
 * 
 * @returns {JSX.Element} The children wrapped within the provider.
 */
const CloudScanProvider:React.FC<ProviderProps> = ({ children }) => {
  
  /**
   * To be implemented.
   */

  return (<>{children}</>)
}

/**
 * Checks what type of scanning is available to the tenant and maps the appropriate provider accordingly.
 * The providers job is to accept scans from students on every single page and parse these 
 * (either in the local browser cache or update to the active lesson).
 * 
 * @param {ProviderProps} props - The props for the component.
 * @param {React.ReactNode} props.children - The child components that this provider will wrap.
 * @returns {JSX.Element} The children wrapped within the provider.
 */
export const HardwareScanProvider:React.FC<ProviderProps> = ({ children }) => {

  const isClient = useIsClient()
  const { license, fetchTenant } = useTenantStore()
  const { pathname } = useRouter()

  useAsync(async () => {
    if(license?.hardwareType === undefined) {
      await fetchTenant();
    }
  }, [license?.hardwareType])

  /**
   * The scan page implements its own hardware scanning functions, so
   * it is unnecessary to push scans to the unresolvedScans state when they
   * are immediately resolved by the scan page.
   */
  if(!license || !isClient || pathname.startsWith('/scannen')) {
    return <> {children} </>
  }

  if(license.hardwareType === 'cloud_scan') 
    return <CloudScanProvider>{children}</CloudScanProvider>;
  else 
    return <BarcodeScanProvider>{children}</BarcodeScanProvider>;
}