import { create } from 'zustand'
import { persist, createJSONStorage, StorageValue } from 'zustand/middleware'
import { schedule } from 'lib/modules'
import moment, { Duration } from 'moment'
import { toast, confirm } from '@skip-scanner/ui'
import { useAppointmentStore } from './appointment'
import type { Appointment } from '@skip-scanner/toolkit/database'
import { immer } from 'zustand/middleware/immer'
import { TRPCError } from '@trpc/server'
import { cloneDeep, merge } from 'lodash'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { env } from 'lib/env.mjs'
import { getSession } from 'next-auth/react'
import { getModuleValue } from 'config'

type ScheduleStore = {

  /**
   * An array of fetched appointments.
   */
  appointments: Array<Appointment>,

  /**
   * The status of the cache. Can be used to show a loading spinner,
   * and to prevent multiple fetches from happening at the same time.
   */
  status: 'idle' | 'syncing' | 'fetching',

  /**
   * Different helper functions for managing the appointments in the cache.
   */
  fns: {
    
    /**
     * Finds an appointment by its id in the cache. If the appointment is not found, 
     * null is returned.
     * 
     * @param {string} id The id of the appointment to find. 
     * @returns {Appointment | null} The appointment if found, or null if not found.
     */
    findById: (id: string) => Appointment | null,

    /**
     * Checks if an appointment exists in the cache.
     * 
     * @param {Appointment} appointment The appointment to check for.
     * @returns {boolean} True if the appointment is in the cache, false if not.
     */
    appointmentInCache: (appointment: Appointment) => boolean,
  },

  /**
   * Controls the background synchronization process of the appointments.
   * Used to manage the internal interval timer that checks for new appointments.
   */
  sync: {

    /**
     * Tries to make a call to `schedule.sync()` to synchronize the appointments with the server.
     * A sync call is only triggered by the following 2 conditions:
     * 1. The difference in seconds between the last sync and now exceeds the sync interval.
     * 2. If no last sync has been performed yet.
     * 
     * If the sync call is triggered, the appointments are concatenated with the existing appointments
     * array and duplicates are removed. The last sync time is updated to the current time.
     * ---
     * **Notice 1:** This function supersedes the `schedule.sync()` function, and should be used instead of it.
     * The `schedule.sync()` function should only be used to directly bypass the cache layer and fetch 
     * appointments directly. 
     * 
     * **Notice 2:** This function internally checks if the timer is running, and if not, starts it. It only 
     * does this when the fetch is for the current week. Fetches for other weeks are not as important, and 
     * they do not get their own timer.
     * 
     * ---
     * @example
     * ```typescript
     * 
     *const { cachedSync } = useScheduleStore(state => state.sync);
     * 
     *useAsync(async () => {
     *  try {
     *    await cachedSync();
     *  }
     *  catch(err) {
     *    const error = err as TRPCError;
     *    console.error(error);
     *    toast(error.message, { type: 'error' });
     *  }
     *}, []);
     * ```
     * ---
     * @param {number} weekNumber The week number to synchronize. If not specified, the current week is synchronized.
     * @returns {Promise<void>} A promise that resolves when the sync call has been performed successfully.
     */
    cachedSync: (weekNumber?: number) => Promise<void>,

    /**
     * Tries to make a call to `schedule.getAppointments()` to fetch the appointments from Firestore.
     * A fetch call is only triggered by the following 2 conditions:
     * 1. The difference in seconds between the last fetch and now exceeds the fetch interval.
     * 2. If no last fetch has been performed yet.
     * 
     * If the fetch call is triggered, the appointments are concatenated with the existing appointments
     * array and duplicates are removed. The last fetch time is updated to the current time.
     * ---
     * **Notice 1:** This function supersedes the `schedule.getAppointments()` function, and should be used instead of it.
     * The `schedule.getAppointments()` function should only be used to directly bypass the cache layer and fetch
     * appointments directly.
     * 
     * **Notice 2:** This function internally checks if the timer is running, and if not, starts it. It only 
     * does this when the fetch is for the current week. Fetches for other weeks are not as important, and 
     * they do not get their own timer.
     * 
     * @param {number} weekNumber The week number to fetch. If not specified, the current week is fetched.
     * @returns {Promise<void>} A promise that resolves when the fetch call has been performed successfully.
     */
    cachedFetch: (weekNumber?: number) => Promise<void>,
    
    /**
     * A map of the last sync and fetch times for each week. This is used to check if a new sync or fetch
     * call needs to be triggered. The key is the week number, and the value is an object with the last sync
     * and fetch times for that week.
     * 
     * **Notice:** when updating a Map in Zustand, you need to create a new Map instance and copy the old values
     * into the new Map. This is because Zustand does not detect changes in Maps, and will not trigger a re-render
     * if you only update the Map values.
     */
    executions: Record<number, {

      /**
       * The last time appointments were synchronized for the specified week. If
       * the diff between this value and the current time exceeds one of the synchronization 
       * intervals defined in `intervals` and a new sync call will be triggered.
       * 
       * If the value is null, no sync has been performed yet and a sync call will be triggered as well.
       * 
       * @type {string | null} An ISO8601 formatted date string, or null if no sync has been performed yet.
       */
      lastSync: string | null,
      
      /**
       * The last time appointments were directly fetched from Firestore (not synced) 
       * for the specified week. If the diff between this value and the current time exceeds
       * one of the fetch intervals defined in `intervals` and a new fetch call will be triggered.
       * 
       * If the value is null, no fetch has been performed yet and a fetch call will be triggered as well.
       * 
       * @type {string | null} An ISO8601 formatted date string, or null if no fetch has been performed yet.
       */
      lastFetch: string | null,
    
    }>
  },

  /**
   * Controls the internal timer that activates the check for the cachedSync and cachedFetch functions.
   */
  timer: {

    /**
     * The internal timer that is used to check for new appointments.
     * If the timer is not running, this value is null.
     * @type {NodeJS.Timer | null}
     */
    id: NodeJS.Timer | null,

    /**
     * Starts the internal timer that activates the check for the cachedSync and cachedFetch functions,
     * for the current week. The timer will be started with the interval set in the `interval` property.
     * 
     * @returns {Promise<void>} A promise that resolves when the timer has been started successfully.
     */
    start: () => Promise<void>,

    /**
     * Stops the internal timer that activates the check for the cachedSync and cachedFetch functions.
     * This is used when the user logs out, for example. When the user is logged in and
     * actively using the app, this timer should always be running.
     * @returns {void}
     */
    stop: () => void,
  }
}

export const useScheduleStore = create<ScheduleStore>()(
  persist(
    immer(
      (set, get) => ({

        appointments: [],

        status: 'idle',

        fns: {
          findById: (id) => get().appointments.find(aptmt => aptmt.id === id) || null,
          appointmentInCache: (appointment) => get().appointments.some(aptmt => aptmt.id === appointment.id)
        },

        sync: {
          executions: {},
          cachedSync: async (weekNumber) => {

            // If the synchronization is already in progress, return
            if(get().status === 'syncing') return;
            
            /**
             * If the synchronization is for the current week. If so, use
             * more agressive intervals to sync the appointments.
             */
            const isCurrentWeek = weekNumber === undefined || weekNumber === moment().isoWeek();
            
            /**
             * The week number to synchronize. If not specified, the current week is synchronized.
             */
            const week = weekNumber || moment().isoWeek();

            /**
             * The Map of the last sync and fetch times for each week.
             */
            const executions = get().sync.executions;

            //#region Check if timer has started (for current week only)

              const { id: timer, start: startTimer } = get().timer;
              if(timer === null && isCurrentWeek) {
                startTimer()
                console.log(`Timer (re)started from cachedSync.`);
              }

            //#endregion

            /**
             * If no previous executions are present, sync the appointments for the week for the first time.
             */
            if(!executions[week]){
              console.log(`Syncing appointments for week ${week} for the first time.`);

              try {
                set(state => { state.status = 'syncing' });
                await schedule.sync(week);
              }
              catch(err) {
                const error = err as TRPCError;
                console.error(error);
              }
              finally {
                set(state => { state.status = 'idle' });
              }
              return;
            }

            const lastExecution = executions[week]
            const lastSync = lastExecution.lastSync ? moment(lastExecution.lastSync) : null;
            const now = moment();

            /**
             * The timeout after which time the schedule.sync() command needs to be called, to synchronize the 
             * appointments with the server. Default value is 15 minutes. The timeout is checked against
             * the `lastSync` value, and if it exceeds the timeout, a new sync call will be triggered.	
             */
            const syncInterval = isCurrentWeek 
              ? schedule.intervals.currentWeekSync 
              : schedule.intervals.otherWeekSync

            /**
             * The difference in milliseconds between the last sync and now.
             */
            const difference = now.diff(lastSync, 'milliseconds');

            /**
             * Wait for the next cached Sync call on these 3 conditions:
             * 1. The difference in seconds between the last sync and now does not exceed the sync interval.
             * 2. If a lastSync is present
             */
            if(difference < syncInterval && lastSync) {
              const remaining = moment.duration(syncInterval - difference, 'milliseconds');
              console.log(`Not syncing appointments for week ${week} ${isCurrentWeek && '(current)'} for another ${remaining.minutes()}m ${remaining.seconds()}s.`);
              return;
            }

            try {
              set(state => { state.status = 'syncing' });
              await schedule.sync(week);
            }
            catch(err) {
              const error = err as TRPCError;
              console.error(error);
            }
            finally {
              set(state => { state.status = 'idle' });
            }
          },
          cachedFetch: async (weekNumber) => {

            // If the fetch is already in progress, return
            if(get().status === 'fetching') return;

            /**
             * If the synchronization is for the current week. If so, use
             * more agressive intervals to sync the appointments.
             */
            const isCurrentWeek = weekNumber === undefined || weekNumber === moment().isoWeek();

            /**
             * The week number to synchronize. If not specified, the current week is synchronized.
             */
            const week = weekNumber || moment().isoWeek();

            /**
             * The Map of the last sync and fetch times for each week.
             */
            const executions = get().sync.executions;

            //#region Check if timer has started (for current week only)

              const { id: timer, start: startTimer } = get().timer;
              if(timer === null && isCurrentWeek) {
                startTimer()
                console.log(`Timer (re)started from cachedFetch.`);
              };

            //#endregion
            
            /**
             * If no previous executions are present, fetch the appointments for the week for the first time.
             */
            if(!executions[week]){
              console.log(`Fetching appointments for week ${week} for the first time.`);

              try {
                set(state => { state.status = 'fetching' });
                await schedule.retrieveAppointments({ weekNumber: week })
              }
              catch(err) {
                const error = err as TRPCError;
                console.error(error);
              }
              finally {
                set(state => { state.status = 'idle' });
              }
              return;
            }

            const lastExecution = executions[week];
            const lastFetch = lastExecution.lastFetch ? moment(lastExecution.lastFetch) : null;
            const now = moment();

            /**
             * The timeout after which time the `schedule.retrieveAppointments()` command needs to be called, 
             * to synchronize the appointments with Firestore. The timeout is checked against the `lastFetch` value,
             * and if it exceeds the timeout, a new fetch call will be triggered.
             */
            const fetchInterval = isCurrentWeek
              ? schedule.intervals.currentWeekFetch
              : schedule.intervals.otherWeekFetch;

            /**
             * The difference in milliseconds between the last fetch and now.
             */
            const difference = now.diff(lastFetch, 'milliseconds');
            
            /**
             * Wait for the next cached Fetch call on these 2 conditions:
             * 1. The difference in seconds between the last fetch and now does not exceed the fetch interval.
             * 2. If a lastFetch time is present
             */
            if(difference < fetchInterval && lastFetch) {
              const remaining = moment.duration(fetchInterval - difference, 'milliseconds');
              console.log(`Not fetching appointments for week ${week} for another ${remaining.minutes()}m ${remaining.seconds()}s.`);
              return;
            }

            try {
              console.log(`Fetching appointments for week ${week} ${isCurrentWeek && '(current)'}`);
              set(state => { state.status = 'fetching' });
              await schedule.retrieveAppointments({ weekNumber: week })
            }
            catch(err) {
              const error = err as TRPCError;
              console.error(error);
            }
            finally {
              set(state => { state.status = 'idle' });
            }
          }
        },

        timer: {
          id: null,
          start: async () => {

            const { cachedFetch, cachedSync } = get().sync;
            const session = await getSession()
            const scheduleType = await getModuleValue('schedule')

            // Clear any existing timer to avoid duplicates
            get().timer.stop();

            if(!session || (scheduleType === 'zermelo' && !session?.user.tokens.zermelo)) {
              return;
            }

            // Start the timer for both syncing and fetching for the current week
            const newTimerId = setInterval(() => {
              cachedSync();
              cachedFetch();
            }, schedule.intervals.timer)

            // Set the timer ID to the new timer
            set(state => { state.timer.id = newTimerId })

          },
          stop: () => set(state => {
            if(state.timer.id !== null) clearInterval(state.timer.id);
            state.timer.id = null;
          })
        },

      })
    ),
    {
      name: 'zustand:schedule-storage',
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        sync: {
          executions: state.sync.executions,
        },
        appointments: state.appointments,
      }),
      merge: (persisted, current) => merge(current, persisted)
    }
  )
)

if(env.NEXT_PUBLIC_NODE_ENV === 'development') {
  mountStoreDevtool('ScheduleStore', useScheduleStore)
}