import React, { useState, forwardRef, useId, useEffect, useRef } from 'react'
import InputMask, { Props as InputMaskProps } from 'react-input-mask'
import { Listbox } from '@headlessui/react'
import { Text } from './Text' 
import { Tooltip } from './Tooltip'
import { EyeIcon, EyeSlashIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/20/solid'
import { omit } from 'lodash'

//#region TextInput

  type TextInputProps = {
    label?: string,
    tooltipText?: React.ReactNode
    children?: React.ReactElement<TextInputDropdownProps>
  } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

  type TextInputDropdownProps = {
    options: Array<{
      id: string,
      displayName: string,
    }> | Array<string>,
    label?: string,
  } & React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>

  type TextInputComponentType = React.ForwardRefExoticComponent< TextInputProps & React.RefAttributes<HTMLInputElement> > & 
  {
    Dropdown: React.ForwardRefExoticComponent< TextInputDropdownProps & React.RefAttributes<HTMLSelectElement> >
  };

  /**
   * The `TextInput` component allows users to enter textual data. 
   * It displays a labeled text input field with an optional tooltip for providing additional information or guidance about the expected input.
   * 
   * @param {string} props.label - Label text displayed above the input to identify the input field to the user.
   * @param {string} [props.tooltipText] - Optional text for a tooltip that will be displayed beside the label to provide further information about the expected input.
   * 
   * @returns {React.Element} The TextInput component rendered as a labeled input field with a supporting tooltip.
   * 
   * @example
   * <TextInput 
   *    label="Username" 
   *    tooltipText="Your unique identifier" 
   *    placeholder="Enter your username"
   * />
   */
  const TextInputRoot = forwardRef<HTMLInputElement, TextInputProps>(({ label, tooltipText, children, ...props }, ref) => {

    const id = useId()
    if(!label && tooltipText) throw new Error('A tooltip needs a label to bind to. Please provide a label if you want to add a Tooltip.')
    
    return (
      <div className={props.className}>
        
        {label && (
          <label htmlFor={id} className="block font-medium leading-6 text-neutral-900 relative mb-2">
            <Text.Label textScale={'label'} textDisplay={'inline'}>{label}</Text.Label>
              
            {tooltipText && 
              <Tooltip tooltipText={tooltipText} className="ml-1 bottom-[2px]"/>
            }

          </label>
        )} 

        <div className="relative rounded-md shadow-sm">

          <input
            {...omit(props, 'className', 'id')}
            id={id}
            ref={ref}
            className="block w-full rounded-lg border-0 py-2.5 px-3.5 pr-20 text-neutral-900 ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-primary-light focus:ring-inset sm:text-sm sm:leading-6 outline-none"
            placeholder={props.placeholder}
          />

          {children}

        </div>
      </div>
    )
  })

  /**
   * `TextInput.Dropdown` is a sub-component of `TextInput` used to render a dropdown menu inside the text input field.
   * This is beneficial when you want to allow users to select from predefined options within the text input. It is positioned
   * to the far right within the input field and styles are applied to maintain consistency with the main TextInput component.
   * The dropdown component utilizes forwardRef to manage ref and also accepts additional properties like `label` to provide a
   * hidden label for accessibility and other native select element attributes through the `props` spread.
   *
   * The `options` prop should be an array of objects where each object represents an option in the dropdown menu. Each option object
   * should have `id` as a unique identifier and `displayName` as the visible name in the dropdown list.
   *
   * @param {Object} props - The properties that define the dropdown options and other attributes.
   * @param {Object[]} props.options - The options to display in the dropdown. Each option is an object with `id` and `displayName` properties.
   * @param {React.Ref} ref - The ref forwarded to the select element.
   * @param {string} [props.label] - A hidden label for the dropdown, beneficial for accessibility.
   *
   * @example
   * ```jsx
   * <TextInput label="Username" placeholder="j.janssen">
   *   <TextInput.Dropdown 
   *     label="Domain"
   *     options={[
   *       { id: '1', displayName: '@osgdl.nl' }, 
   *       { id: '2', displayName: '@doultremontcollege.nl' }, 
   *       { id: '3', displayName: '@sgdelangstraat.nl' },
   *     ]}
   *   />
   * </TextInput>
   * ```
   */
  const Dropdown = forwardRef<HTMLSelectElement, TextInputDropdownProps>(({ options, label, ...props }, ref) => {

    const id = useId()

    return (
      <div className="absolute inset-y-0 right-0 flex items-center">
        { label && <label htmlFor={id} className="sr-only">{label}</label> }
        <select
          {...omit(props, 'className', 'id')}
          id={id}
          ref={ref}
          className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-neutral-500 sm:text-sm focus:ring-inset focus:ring-2 focus:ring-primary-light outline-none"
        >
          {options.map(option => typeof option == 'string' 
            ? 
            <option key={option} value={option}>{option}</option>
            : 
            <option key={option.id} value={option.id}>{option.displayName}</option>
          )}
        </select>
      </div>
    )
  })

  export const TextInput = Object.assign(TextInputRoot, { Dropdown }) as TextInputComponentType

//#endregion

//#region PasswordInput

  type PasswordInputProps = {
    label?: string,
    tooltipText?: React.ReactNode
  } & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

  /**
   * The `PasswordInput` component allows users to securely enter password data. 
   * It displays a labeled password input field with a visibility toggle button allowing users 
   * to switch between visible and masked password. 
   * It also supports an optional tooltip for providing additional information or guidance about the password requirements.
   * 
   * @param {string} props.label - Label text displayed above the input to identify the input field to the user.
   * @param {string} [props.tooltipText] - Optional text for a tooltip that will be displayed beside the label to provide further information about the password requirements.
   * 
   * @returns {React.Element} The PasswordInput component rendered as a labeled password field with a visibility toggle and a supporting tooltip.
   * 
   * @example
   * <PasswordInput 
   *    label="Password" 
   *    tooltipText="Your secure password" 
   *    placeholder="Enter your password"
   * />
   */
  export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(({ label, tooltipText, ...props }, ref) => {

    const [ passwordVisible, setPasswordVisible ] = useState<boolean>(false)
    const id = useId()
    if(!label && tooltipText) throw new Error('A tooltip needs a label to bind to. Please provide a label if you want to add a Tooltip.')

    return (
      <div className={props.className}>
        <label htmlFor={id} className="block font-medium leading-6 text-neutral-900 mb-2">

          {label && <Text.Label textScale={'label'} textDisplay={'inline'}>{label}</Text.Label>} 
            
          {tooltipText && 
            <Tooltip tooltipText={tooltipText} className="ml-1 bottom-[2px]"/>
          }

        </label>
        <div className="relative rounded-md shadow-sm">

          <input
            {...omit(props, 'className', 'id')}
            id={id}
            ref={ref}
            type={passwordVisible ? 'text' : 'password'}
            className={`block w-full rounded-lg border-0 py-2.5 px-3.5 pr-20 text-neutral-900 ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:!ring-primary-light focus:ring-inset sm:text-sm sm:leading-6 outline-none`}
            placeholder={props.placeholder}
          />

          <div 
            className='absolute inset-y-0 flex items-center h-full right-3 cursor-pointer' 
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? 
              <EyeSlashIcon className='h-6 w-6 text-neutral-500'/>
            : 
              <EyeIcon className='h-6 w-6 text-neutral-500'/>
            }
          </div>

        </div>
      </div>
    )

  })

//#endregion

//#region MaskedInput

  type MaskedInputProps = {
    label?: string,
    tooltipText?: React.ReactNode
  } & InputMaskProps

  /**
   * The `MaskedInput` component provides a text input field with a customizable mask, 
   * helping users to input data in a specific format.
   * 
   * @component
   * @example
   * ```jsx
   * <MaskedInput 
   *   label="Date of Birth"
   *   tooltipText="Please enter in the format: MM/DD/YYYY"
   *   mask="99/99/9999"
   *   placeholder="MM/DD/YYYY"
   * />
   * ```
   * 
   * @param {object} props - The properties object.
   * @param {string} props.label - The label to be displayed for the input field.
   * @param {React.ReactNode} [props.tooltipText] - The text to be displayed in the tooltip which appears on hover.
   * 
   * @param {string} [props.mask] - The mask pattern for the input field using the following format characters:
   *   - 9: allows a character in the range 0-9
   *   - a: allows a character in the range A-Z or a-z
   *   - *: allows a character in the range A-Z, a-z, or 0-9
   * Any character in the mask can be escaped with a backslash, appearing typically as a double backslash in JS strings.
   * For example, a German phone mask with unremovable prefix +49 would be represented as `mask="+4\\9 99 999 99"` or `mask={"+4\\\\9 99 999 99"}`.
   * 
   * @param {string} [props.placeholder] - The placeholder text for the input field.
   * @param {React.Ref<HTMLInputElement>} ref - A ref to be forwarded to the input element.
   * 
   * @returns {React.Element} - The rendered `MaskedInput` component.
   */
  export const MaskedInput = forwardRef<HTMLInputElement, MaskedInputProps>(({ label, tooltipText,  ...props }, ref) => {

    const id = useId()
    if(!label && tooltipText) throw new Error('A tooltip needs a label to bind to. Please provide a label if you want to add a Tooltip.')

    // Convert forwardedRef to legacyRef
    const legacyRef = useRef(null);
    useEffect(() => {
      if (typeof ref === 'function') {
        ref(legacyRef.current);
      } else if (ref) {
        ref.current = legacyRef.current;
      }
    }, [ref]);

    return (
      <div className={props.className}>

        {label && (
          <label htmlFor={id} className="block font-medium leading-6 text-neutral-900 relative mb-2">
            <Text.Label textScale={'label'} textDisplay={'inline'}>{label}</Text.Label>
              
            {tooltipText && 
              <Tooltip tooltipText={tooltipText} className="ml-1 bottom-[2px]"/>
            }

          </label>
        )}

        <div className="relative rounded-md shadow-sm">

          <InputMask 
            {...omit(props, 'className', 'id')}
            id={id}
            className="block w-full rounded-lg border-0 py-2.5 px-3.5 text-neutral-900 ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-primary-light focus:ring-inset sm:text-sm sm:leading-6 outline-none"
            ref={legacyRef}
            maskPlaceholder={props.maskPlaceholder}
            mask={props.mask}
            placeholder={props.placeholder}
            alwaysShowMask
          />

        </div>
      </div>
    )
  })

//#endregion

//#region Select

  type SelectProps = {
    label?: string,
    tooltipText?: React.ReactNode
    options: Array<{ id: string; displayName: string }> | Array<string>
  } & Omit<React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, 'onChange'>

  /**
   * The `Select` component is a custom wrapper around the HTML `select` element which allows users to choose an option from a dropdown list.
   * 
   * @component
   * @example
   * ```jsx
   * <Select 
   *   label="Fruits"
   *   tooltipText="Please select your favorite fruit"
   *   options={[
   *     { id: '1', displayName: 'Apple' },
   *     { id: '2', displayName: 'Banana' },
   *     { id: '3', displayName: 'Cherry' },
   *   ]}
   * />
   * ```
   * 
   * @param {object} props - The properties object.
   * @param {string} props.label - The label to be displayed above the select field.
   * @param {React.ReactNode} [props.tooltipText] - The text to be displayed in the tooltip which appears on hover.
   * @param {(Array<{ id: string; displayName: string }> | Array<string>)} props.options - The options for the select field. It accepts an array of objects with `id` and `displayName` properties, or an array of strings.
   * 
   * @param {React.Ref<HTMLSelectElement>} ref - A ref to be forwarded to the select element.
   * 
   * @returns {React.Element} - The rendered `Select` component.
   */
  export const Select = forwardRef<HTMLSelectElement, SelectProps>(({ options, label, tooltipText, ...props }, ref) => {

    const id = useId()
    if(!label && tooltipText) throw new Error('A tooltip needs a label to bind to. Please provide a label if you want to add a Tooltip.')

    return (
      <div className={props.className}>

        {label && (
          <label htmlFor={id} className="block font-medium leading-6 mb-2 text-neutral-900 relative">
            <Text.Label textScale={'label'} textDisplay={'inline'}>{label}</Text.Label>
              
            {tooltipText && 
              <Tooltip tooltipText={tooltipText} className="ml-1 bottom-[2px]"/>
            }

          </label>
        )}
        <div className='relative rounded-md shadow-sm'>
          <select
            {...omit(props, 'className', 'id')}
            id={id}
            ref={ref}
            placeholder={props.placeholder}
            className="block w-full rounded-lg border-0 py-2.5 px-3.5 text-neutral-500 ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-primary-light focus:ring-inset sm:text-sm sm:leading-6 outline-none pr-7 bg-transparent h-11"
          >
            {options.map(option => typeof option == 'string' 
              ? 
              <option key={option} value={option}>{option}</option>
              : 
              <option key={option.id} value={option.id}>{option.displayName}</option>
            )}
          </select>
        </div>
      </div>
    )
  })

//#endregion