import { motion, type SVGMotionProps } from 'framer-motion'
import { cva, VariantProps } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import { omit } from 'lodash'

const IconStyles = cva('inline-block relative aspect-square aria-disabled:text-opacity-40', {
  variants: {
    iconColor: {
      'primary-lighter': 'text-primary-lighter',
      'primary-light': 'text-primary-light',
      'primary': 'text-primary',
      'primary-dark': 'text-primary-dark',
      'primary-darker': 'text-primary-darker',
      'accent': 'text-accent',
      'white': 'text-white',
      'light': 'text-light',
      'dark': 'text-dark'
    }
  },
  defaultVariants: {
    iconColor: 'dark'
  }
})

export type IconType = {
  name?: string
} 
& VariantProps<typeof IconStyles> 
& SVGMotionProps<SVGSVGElement>

export const EyeIcon:React.FC<IconType> = ({ name = 'EyeIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='currentColor'
      viewBox='0 0 24 24'
      {...omit(props, 'className')}
    >
      <motion.path layout d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
      <motion.path layout fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z" clipRule="evenodd" />
    </motion.svg>
  )
}

export const DashboardIcon:React.FC<IconType> = ({ name = 'DashboardIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <motion.circle layout cx="12" cy="13" r="2" />
      <motion.line layout x1="13.45" y1="11.55" x2="15.5" y2="9.5" />
      <motion.path layout d="M6.4 20a9 9 0 1 1 11.2 0z" />
    </motion.svg>
  )
}

export const CalendarIcon:React.FC<IconType> = ({ name = 'CalendarIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
    </motion.svg>
  )
}

export const ChartBarSquareIcon:React.FC<IconType> = ({ name = 'ChartBarSquareIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
    </motion.svg>
  )
}

export const HomeIcon:React.FC<IconType> = ({ name = 'HomeIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path 
        layout 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" 
      />

    </motion.svg>
  )
}

export const UserIcon:React.FC<IconType> = ({ name = 'UserIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
    </motion.svg>
  )
}

export const UsersIcon:React.FC<IconType> = ({ name = 'UsersIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
    </motion.svg>
  )
}

export const UserGroupIcon:React.FC<IconType> = ({ name = 'UserGroupIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
    </motion.svg>
  )
}

export const ChevronDoubleRightIcon:React.FC<IconType> = ({ name = 'ChevronDoubleRightIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout strokeLinecap="round" strokeLinejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
    </motion.svg>
  )
}

export const ChevronRightIcon:React.FC<IconType> = ({ name = 'ChevronRightIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
    </motion.svg>
  )
}

export const SettingsIcon:React.FC<IconType> = ({ name = 'SettingsIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout strokeLinecap="round" strokeLinejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
    </motion.svg>
  )
}

export const PlayIcon:React.FC<IconType> = ({ name = 'PlayIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout strokeLinecap="round" strokeLinejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
    </motion.svg>
  )
}

export const BackwardIcon:React.FC<IconType> = ({ name = 'BackwardIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout strokeLinecap="round" strokeLinejoin="round" d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z" />
    </motion.svg>
  )
}

export const ForwardIcon:React.FC<IconType> = ({ name = 'ForwardIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout strokeLinecap="round" strokeLinejoin="round" d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z" />
    </motion.svg>
  )
}

export const AcademicCapIcon:React.FC<IconType> = ({ name = 'AcademicCapIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path layout strokeLinecap="round" strokeLinejoin="round" d="M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5" />

    </motion.svg>
  )
}

export const InformationCircleIcon:React.FC<IconType> = ({ name = 'InformationCircleIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
    </motion.svg>
  )
}

export const SaveIcon:React.FC<IconType> = ({ name = 'SaveIcon', iconColor, ...props }) => {
  
  return (
    <motion.svg
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <motion.path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
      <motion.path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
      <motion.path d="M14 4l0 4l-6 0l0 -4" />
    </motion.svg>
  )
}

export const FacebookIcon:React.FC<IconType> = ({ name = 'FacebookIcon', iconColor, ...props }) => {

  return (
    <motion.svg 
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <motion.path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
    </motion.svg>
  )
}

export const InstagramIcon:React.FC<IconType> = ({ name = 'InstagramIcon', iconColor, ...props }) => {

  return (
    <motion.svg 
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <motion.path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
      <motion.path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
      <motion.path d="M16.5 7.5l0 .01" />
    </motion.svg>
  )
}

export const LinkedInIcon:React.FC<IconType> = ({ name = 'LinkedInIcon', iconColor, ...props }) => {

  return (
    <motion.svg 
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <motion.path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
      <motion.path d="M8 11l0 5" />
      <motion.path d="M8 8l0 .01" />
      <motion.path d="M12 16l0 -5" />
      <motion.path d="M16 16v-3a2 2 0 0 0 -4 0" />
    </motion.svg>
  )
}

export const XMarkIcon:React.FC<IconType> = ({ name = 'XMarkIcon', iconColor, ...props }) => {

  return (
    <motion.svg 
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
    </motion.svg>
  )
}

export const DeviceTabletSearchIcon:React.FC<IconType> = ({ name = 'DeviceTabletSearchIcon', iconColor, ...props }) => {

  return (
    <motion.svg 
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <motion.path d="M11.5 21h-5.5a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v7" />
      <motion.path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
      <motion.path d="M20.2 20.2l1.8 1.8" />
    </motion.svg>
  )
}

export const BellAlertIcon:React.FC<IconType> = ({ name = 'DeviceBellAlertIcon', iconColor, ...props }) => {

  return (
    <motion.svg 
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
    </motion.svg>
  )
}

export const ClipboardDocumentListIcon:React.FC<IconType> = ({ name = 'ClipboardDocumentListIcon', iconColor, ...props }) => {

  return (
    <motion.svg 
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox='0 0 24 24'
      strokeWidth='1.5' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z" />
    </motion.svg>
  )
}

export const ActieflowIcon:React.FC<IconType> = ({ name = 'ActieflowIcon', iconColor, ...props }) => {

  return (
    <motion.svg 
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox="0 0 77 45"
      strokeWidth='4' 
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path d="M282.67,418.81a3.67,3.67,0,1,0,3.67-3.67,3.67,3.67,0,0,0-3.67,3.67" transform="translate(-268.13 -384.31)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <motion.path d="M304.67,396.81a3.67,3.67,0,1,0,3.67-3.67,3.67,3.67,0,0,0-3.67,3.67" transform="translate(-268.13 -384.31)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <motion.path d="M289.09,416.06l16.5-16.5" transform="translate(-268.13 -384.31)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <motion.path d="M337.78,397.67a3.67,3.67,0,1,0,5.13.74,3.67,3.67,0,0,0-5.13-.74" transform="translate(-268.13 -384.31)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <motion.path d="M313,396.68l23.09,3.37" transform="translate(-268.13 -384.31)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      <motion.g>
        <motion.path d="M271.8,392.75a3.67,3.67,0,1,0-2-4.78,3.66,3.66,0,0,0,2,4.78" transform="translate(-268.13 -384.31)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        <motion.path d="M283.8,414.41l-9.11-21.48" transform="translate(-268.13 -384.31)" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
      </motion.g>
    </motion.svg>
  )
}

export const LessonsBookIcon:React.FC<IconType> = ({ name = 'ActieflowIcon', iconColor, ...props }) => {

  return (
    <motion.svg 
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox="0 0 165.3 165.3"
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <g>
        <path d="M340.36,351.19v82.65a13.78,13.78,0,0,1-13.78,13.78H257.71a13.77,13.77,0,0,1-13.77-13.78V351.19a13.77,13.77,0,0,1,13.77-13.77h68.87A13.78,13.78,0,0,1,340.36,351.19Z" transform="translate(-202.61 -309.87)" style={{ strokeWidth: '10px' }}/>
        <path d="M230.16,365h20.66" transform="translate(-202.61 -309.87)" style={{ strokeWidth: '10px' }}/>
        <path d="M230.16,392.52h20.66" transform="translate(-202.61 -309.87)" style={{ strokeWidth: '10px' }}/>
        <path d="M230.16,420.07h20.66" transform="translate(-202.61 -309.87)" style={{ strokeWidth: '10px' }}/>
      </g>
      <g>
        <path d="M324.71,383.61l-31.95-12.78-32,12.78,32,12.78,31.95-12.78v19.17" transform="translate(-202.61 -309.87)" style={{ strokeWidth: '7px' }}/>
        <path d="M273.58,388.72V406c0,5.3,8.59,9.59,19.18,9.59s19.17-4.29,19.17-9.59V388.72" transform="translate(-202.61 -309.87)" style={{ strokeWidth: '7px' }}/>
      </g>
    </motion.svg>
  )
}

export const DownloadIcon:React.FC<IconType> = ({ name = 'DownloadIcon', iconColor, ...props }) => {

  return (
    <motion.svg 
      className={twMerge( 
        props.className?.toString(), 
        IconStyles({ iconColor }) 
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox="0 0 24 24"
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
    </motion.svg>
  )
}

export const MagnifyingGlassIcon:React.FC<IconType> = ({ name = 'MagnifyingGlassIcon', iconColor, ...props }) => {

  return (
    <motion.svg 
      className={twMerge(
        props.className?.toString(),
        IconStyles({ iconColor })
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox="0 0 24 24"
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
    </motion.svg>
  )
}

export const DatabaseIcon:React.FC<IconType> = ({ name = 'DatabaseIcon', iconColor, ...props }) => {
  return (
    <motion.svg 
      className={twMerge(
        props.className?.toString(),
        IconStyles({ iconColor })
      )}
      aria-label={name}
      layout
      fill='none'
      viewBox="0 0 24 24"
      stroke='currentColor'
      strokeLinecap='round' 
      strokeLinejoin='round'
      {...omit(props, 'className')}
    >
      <motion.path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
    </motion.svg>
  )
}