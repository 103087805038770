import React, { useState } from 'react'
import { Text } from '@skip-scanner/ui'
import { UnDrawAccessDenied } from '@skip-scanner/ui/graphics/undraw'
import { useIsClient, useWindowSize } from 'usehooks-ts'
import { isMobileOnly } from "react-device-detect";
import { 
  motion,
  AnimatePresence, 
  type Transition,
  type HTMLMotionProps
} from 'framer-motion'

/**
 * Props for the BreakpointOverlay component.
 * 
 * @property {Object} windowDimensions - Object containing the width and height of the window.
 * @property {number} windowDimensions.width - Width of the window.
 * @property {number} windowDimensions.height - Height of the window.
 * @property {Function} onStart - Callback function to execute when the animation starts.
 * @property {Function} onComplete - Callback function to execute when the animation completes.
 */
type BreakpointOverlayProps = 
  { windowDimensions: { width: number, height: number }, breakpointWidth: number } 
  & Pick<HTMLMotionProps<'div'>, 'onAnimationStart' | 'onAnimationComplete'>

/**
 * The BreakpointOverlay component. A motion-enabled overlay that initially has rounded 
 * corners during transitioning but takes on square corners when it reaches its final destination.
 * It provides visual feedback when the window's width is below a certain threshold.
 * 
 * @param {BreakpointOverlayProps} props - Component properties.
 * @returns {JSX.Element} The rendered motion-enabled div.
 */
const BreakpointOverlay: React.FC<BreakpointOverlayProps> = ({ 
  windowDimensions: { height, width }, 
  onAnimationStart, 
  onAnimationComplete,
  breakpointWidth
}) => {

  /**
   * Default transition settings for the motion-enabled div.
   * It defines the behavior for "y" positioning and `borderRadius` during transitions.
   */
  const defaultTransition: Transition = {
    y: {
      type: 'spring',
      stiffness: 500,
      damping: 75,
    },
    borderRadius: {
      duration: 0.25,   // This can be adjusted based on preferences
      delay: 0.25,     // This delay ensures the borderRadius transition starts after y completes
    },
  }

  return (
    <motion.div 
      transition={defaultTransition} 
      className='inset-0 absolute bg-gradient-to-br from-primary-darker to-primary-light w-screen h-screen z-50 flex flex-col items-center py-[10vh]'
      variants={{ 
        hidden: {
          y: height,
          borderRadius: '2.5rem 2.5rem 0 0',  // rounded corners
        },
        visible: {
          y: 0,
          borderRadius: '0', // square corners
        },
      }}
      initial="hidden"
      animate="visible"
      exit="hidden"
      onAnimationStart={onAnimationStart}
      onAnimationComplete={onAnimationComplete}
    >

      <div className='px-6'>

        <Text.Heading textScale={'heading'} textColor={'white'}>Oeps! Er ging iets fout.</Text.Heading>

        <Text.Paragraph textScale={'paragraph'} className='mt-2 w-11/12 sm:w-3/4 text-neutral-400'>
          Helaas is Skip Scanner niet geoptimaliseerd voor mobiele apparaten. 
          Voor volledige toegang en functionaliteit verzoeken we u om in te loggen vanaf een apparaat met een groter scherm.
        </Text.Paragraph>

        {(breakpointWidth && !isMobileOnly) && <>

          <div className="border rounded-md border-primary-lighter mt-8 max-w-max px-3 py-2">
            <Text.Label className='font-jetbrains-mono' textColor={'light'}>Uw schermdimensies: {width} x {height} px</Text.Label>
          </div>
          <div className="border rounded-md border-primary-lighter mt-3 max-w-max px-3 py-2">
            <Text.Label className='font-jetbrains-mono' textColor={'light'}>Minimale schermdimensies: {breakpointWidth} x {breakpointWidth} px</Text.Label>
          </div>
        
        </>}


      </div>

      <UnDrawAccessDenied className="absolute right-0 w-2/3 bottom-[10vh]"/>

    </motion.div>
  )
}

type BreakpointOverlayProviderProps = { children?: React.ReactNode }

/**
 * `BreakpointOverlayProvider` Component.
 * 
 * This component wraps around other components to provide a mechanism for 
 * showing or hiding children based on the current viewport size. 
 * It uses a breakpoint to determine whether the `BreakpointOverlay` should be rendered.
 * If the viewport width is below the specified breakpoint, or if the user is on a mobile device, 
 * the `BreakpointOverlay` will be shown. Depending on the animation state, child components 
 * might be rendered or hidden.
 *
 * @component
 * @example
 * ```jsx
 * <BreakpointOverlayProvider>
 *   <YourComponent />
 * </BreakpointOverlayProvider>
 * ```
 * @param {Object} props The properties for the component.
 * @param {React.ReactNode} props.children The children components that will be conditionally rendered.
 * @returns {React.ReactElement|null} Returns the `BreakpointOverlay` component if conditions match, 
 *          the children if they can be shown, or null if not in a client environment.
 */
export const BreakpointOverlayProvider:React.FC<BreakpointOverlayProviderProps> = ({ children }) => {

  const isClient = useIsClient()
  const { height, width } = useWindowSize()
  const [ showComponents, setShowComponents ] = useState<boolean>(true);

  const BREAKPOINT_WIDTH = 768 // Everything smaller than md

  if(!isClient) return null

  return (
    <>
      <AnimatePresence>
        {(width < BREAKPOINT_WIDTH || isMobileOnly) && 
          <BreakpointOverlay 
            windowDimensions={{ height, width }} 
            breakpointWidth={BREAKPOINT_WIDTH}
            onAnimationStart={(type) => {
              if (type === "hidden") setShowComponents(true)
            }}
            onAnimationComplete={(type) => {
              if (type !== "hidden") setShowComponents(false)
            }}
          />
        }
      </AnimatePresence>
    
      {/* Render children if props can be shown */}
      {showComponents && <>{children}</> }

    </>
  )
}