import { Appointment } from '@skip-scanner/toolkit/database'
import { ScheduleProvider, IScheduleProvider } from './base'
import { trpx } from 'lib/trpc';
import moment from 'moment';
import { getSession } from 'next-auth/react';
import { TRPCError } from '@trpc/server';
import { useScheduleStore } from 'lib/stores';
import { toast } from '@skip-scanner/ui';

/**
 * The **ZermeloScheduleProvider** class is an implementation of the **ScheduleProvider** class.
 * It fetches the schedule from a school-specific Zermelo API endpoint and syncs it to the Skip Scanner database.
 * Ready for usage in production.
 * 
 * Zermelo events are parsed and converted to Skip Scanner appointments. Requires an employee-specific
 * Zermelo API token to fetch the schedule.
 */
export class ZermeloScheduleProvider extends ScheduleProvider {

  /**
   * Synchronizes the schedule with the Skip Scanner database.
   * Internally, it calls the different private methods of the different schedule providers
   * to fetch the schedule, parse it, and sync it to the database.
   * 
   * **Notice:** This method internally updates the client-side scheduleStore with the newly fetched appointments.
   * So you don't need to call `useScheduleStore.setState` after calling this method.
   * ---
   * @param {number} weekNumber The week number to sync the schedule for. If not specified, the current week is used.
   * @throws {TRPCError} If the appointment synchronization fails.
   * @returns {Promise<Appointment[]>} A promise that resolves to all the appointments for that week, incl. newly synced appointments.
   */
  public sync: IScheduleProvider['sync'] = async (week) => {

    const weekNumber = week ?? moment().isoWeek();
    const session = await getSession();

    //#region Input and session validation

      if(!session) {
        throw new TRPCError({
          code: 'UNAUTHORIZED',
          message: 'Log gelieve opnieuw in om uw rooster op te halen vanuit Zermelo.'
        })
      }

      if(!session.user.tokens.zermelo || !session.user.code) {
        throw new TRPCError({
          code: 'UNAUTHORIZED',
          message: 'Uw account is niet gekoppeld aan Zermelo. Koppel deze in uw instellingen om gebruik te maken van deze functie.'
        })
      }

    //#endregion

    const appointments = await trpx.modules.schedule.zermelo.sync.mutate({ weekNumber })
    this.updateCache(weekNumber, appointments, 'sync');

    return appointments;
  };

  constructor() {
    super();
  }
}