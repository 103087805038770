import React from 'react';
import Link from 'next/link';
import { cva, VariantProps } from 'class-variance-authority'
import twMerge from '../tailwind-merge.config'
import { motion, type HTMLMotionProps } from 'framer-motion';
import { omit } from 'lodash';

/**
 * CVA styles for each of the Text components.
 */

const TextStyles = cva('font-mundial', {
  variants: {
    textColor: {
      'dark': 'text-dark',
      'light': 'text-light',
      'primary-darker': 'text-primary-darker',
      'primary-dark': 'text-primary-dark',
      'primary': 'text-primary',
      'primary-light': 'text-primary-light',
      'primary-lighter': 'text-primary-lighter',
      'white': 'text-white ',
      'accent': 'text-accent',

      'gradient-to-b': 'text-transparent bg-clip-text bg-gradient-to-b',
      'gradient-to-r': 'text-transparent bg-clip-text bg-gradient-to-r',
      'gradient-to-br': 'text-transparent bg-clip-text bg-gradient-to-br',
      'gradient-to-tr': 'text-transparent bg-clip-text bg-gradient-to-tr'
    },
    textDisplay: {
      'block': 'block',
      'inline-block': 'inline-block',
      'inline': 'inline'
    },
    textScale: {
      'display': 'text-display-xs sm:text-display-sm lg:text-display-md 2xl:text-display-lg',
      'heading': 'text-heading-sm sm:text-heading-lg lg:heading-xl 2xl:text-heading-2xl',
      'label': 'text-label-sm sm:text-label-md 2xl:text-label-lg',
      'paragraph': 'text-paragraph-sm sm:text-paragraph-md 2xl:text-paragraph-lg',
      'none': ''
    },
    textIntent: {
      'link': 'hover:underline text-primary-lighter w-max',
      'quote': ' py-4 px-4 border-l-[6px] border-l-primary-light rounded-l-xl bg-gradient-to-r from-dark/10',
      'span': '',
      'none': ''
    }
  },
  defaultVariants: {
    textDisplay: 'block',
    textColor: 'dark',
    textScale: 'none',
    textIntent: 'none'
  }
})

/**
 * Props for each of the component the Text object holds.
 */
export type TextComponentProps = VariantProps<typeof TextStyles> & HTMLMotionProps<'p'> & { href?: string }

/**
 * Props for the outer Text object wrapper itself.
 */
type TextProps = React.ForwardRefExoticComponent<React.HTMLAttributes<HTMLHeadingElement>> & {
  Display: React.FC<TextComponentProps>,
  Heading: React.FC<TextComponentProps>,
  Label: React.FC<TextComponentProps>,
  Paragraph: React.FC<TextComponentProps>,
  Blockquote: React.FC< Omit<VariantProps<typeof TextStyles>, 'textIntent'> & HTMLMotionProps<'blockquote'>>
}

/**
 * The default Text styles used inside the Skip-Scanner UI.
 */
export const Text = {
  Display: ({ textColor, textDisplay, textScale, textIntent = 'none', href, ...props }) => {

    if(textIntent == 'link' && !href) throw new Error('Please input an href attribute for your link.')

    switch(textIntent) {

      case 'link': {
        return (
          <Link href={href as string}>
            <motion.div 
              className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent }) )}
              {...omit(props, 'className')}
            >

              {props.children}
            </motion.div>
        </Link>
        )
      }

      case 'span': {
        return (
          <motion.span
            className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent }) )}
            {...omit(props, 'className')}
          >
            {props.children}
          </motion.span>
        )
      }

      case 'none': {
        return (
          <motion.h1
            className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent }) )}
            {...omit(props, 'className')}
          >
            {props.children}
          </motion.h1>
        )
      }
      
    }

  },
  Heading: ({ textColor, textDisplay, textScale, textIntent = 'none', href, ...props }) => {
    
    if(textIntent == 'link' && !href) throw new Error('Please input an href attribute for your link.')

    switch(textIntent) {

      case 'link': {
        return (
          <Link href={href as string}>
            <motion.div 
              className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent }) )}
              {...omit(props, 'className')}
            >

              {props.children}
            </motion.div>
        </Link>
        )
      }

      case 'span': {
        return (
          <motion.span
            className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent }) )}
            {...omit(props, 'className')}
          >
            {props.children}
          </motion.span>
        )
      }

      case 'none': {
        return (
          <motion.h2
            className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent }) )}
            {...omit(props, 'className')}
          >
            {props.children}
          </motion.h2>
        )
      }
      
    }

  },
  Label: ({ textColor, textDisplay, textScale, textIntent = 'none', href, ...props }) => {

    if(textIntent == 'link' && !href) throw new Error('Please input an href attribute for your link.')

    switch(textIntent) {

      case 'link': {
        return (
          <Link href={href as string}>
            <motion.div 
              className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent }) )}
              {...omit(props, 'className')}
            >
              {props.children}
            </motion.div>
        </Link>
        )
      }

      case 'span': {
        return (
          <motion.span
            className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent }) )}
            {...omit(props, 'className')}
          >
            {props.children}
          </motion.span>
        )
      }

      case 'none': {
        return (
          <motion.h4
            className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent }) )}
            {...omit(props, 'className')}
          >
            {props.children}
          </motion.h4>
        )
      }
      
    }

  },
  Paragraph: ({ textColor, textDisplay, textScale, textIntent = 'none', href, ...props }) => {

    if(textIntent == 'link' && !href) throw new Error('Please input an href attribute for your link.')

    switch(textIntent) {

      case 'link': {
        return (
          <Link href={href as string}>
            <motion.div 
              className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent }) )}
              {...omit(props, 'className')}
            >

              {props.children}
            </motion.div>
        </Link>
        )
      }

      case 'span': {
        return (
          <motion.span
            className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent }) )}
            {...omit(props, 'className')}
          >
            {props.children}
          </motion.span>
        )
      }

      case 'none': {
        return (
          <motion.p
            className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent }) )}
            {...omit(props, 'className')}
          >
            {props.children}
          </motion.p>
        )
      }
      
    }

  },
  Blockquote: ({ textColor, textDisplay, textScale, ...props }) => {

    return (
      <motion.blockquote 
        className={twMerge( props.className, TextStyles({ textColor, textDisplay, textScale, textIntent: 'quote' }) )}
        {...omit(props, 'className')}
      >
        {props.children}
      </motion.blockquote>
    )
  }
} as TextProps;