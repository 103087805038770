import type { Appointment } from '@skip-scanner/toolkit/database'
import { firestore } from '@skip-scanner/toolkit/firebase/client'
import { TRPCError } from '@trpc/server';
import { LESSON_INVITE_CODE } from '@skip-scanner/toolkit/lib/validators'
import { useScheduleStore, useAppointmentStore } from 'lib/stores'
import { getSession } from 'next-auth/react';
import type { AppointmentStatus } from "@skip-scanner/toolkit/types/base";
import moment from 'moment';
import {
  doc,
  getDoc,
  getDocs,
  collection,
  query,
  where,
  limit,
  orderBy,
  type Query,
  type DocumentData
} from 'firebase/firestore'
import { trpx } from 'lib/trpc';

/**
 * The default interface for the schedule provider singleton factory.
 * This interface defines the public functions and variables needed 
 * for every schedule provider.
 */
export interface IScheduleProvider {

  /**
   * Defines the intervals that are used for cached schedule fetching and synchronisation.
   * These intervals are defined as read-only values, to discourage changing them at runtime
   * on the client side. The intervals are used to determine the minimum times between different
   * schedule operations, such as fetching, syncing, and timer intervals.
   * 
   * @readonly
   */
  intervals: {

    /**
     * Defines the minimum interval for schedule synchronization, in the current week.
     * The current week needs to be updated more regularly than the other weeks, to ensure
     * that the user has the most recent schedule available. A good default value is 15 minutes.
     * 
     * **Notice:** This interval is defined in milliseconds.
     * @readonly
     */
    currentWeekSync: number,

    /**
     * Defines the minimum interval for fetching the appointments for the current week
     * from the database. This interval is used to prevent unnecessary database reads.
     * The current week needs to be updated more regularly than the other weeks, to ensure
     * that the user has the most recent schedule available. A good default value is 5 minutes.
     * 
     * **Notice 1:** This interval is defined in milliseconds.
     * 
     * **Notice 2:** This interval should be higher than the `currentWeekSync` interval.
     * @readonly
     */
    currentWeekFetch: number,

    /**
     * Defines the minimum interval for schedule synchronization, in other weeks than the current week.
     * The other weeks need to be updated less regularly than the current week, to prevent unnecessary
     * API calls and database writes. A good default value is 1 hour.
     * 
     * **Notice:** This interval is defined in milliseconds.
     * @readonly
     */
    otherWeekSync: number,

    /**
     * Defines the minimum interval for fetching the appointments for other weeks than the current week.
     * This interval is used to prevent unnecessary database reads. The other weeks need to be updated
     * less regularly than the current week, to prevent unnecessary API calls and database writes.
     * A good default value is 20 minutes.
     * 
     * **Notice 1:** This interval is defined in milliseconds.
     * 
     * **Notice 2:** This interval should be higher than the `otherWeekSync` interval.
     * @readonly
     */
    otherWeekFetch: number,

    /**
     * An internal client-side `setInterval`-timer is used to automatically sync and fetch the schedule
     * for the current week. For any other weeks, the `cachedSync` and `cachedFetch` methods are ran
     * only when neccessary. This variable defines the interval / delay for the client-side timer.
     * 
     * **Notice:** This interval is defined in milliseconds.
     * @readonly
     */
    timer: number
  },

  /**
   * Synchronizes the schedule with the Skip Scanner database.
   * Internally, it calls the different private methods of the different schedule providers
   * to fetch the schedule, parse it, and sync it to the database.
   * ---
   * @param {number} weekNumber The week number to sync the schedule for. If not specified, the current week is used.
   * @throws {TRPCError} If the appointment synchronization fails.
   * @returns {Promise<Appointment[]>} A promise that resolves to all the appointments for that week, incl. newly synced appointments.
   */
  sync: (weekNumber?: number) => Promise<Appointment[]>,
  
  /**
   * Fetches the schedule for a user from the database. Also includes non-scannable appointments.
   * Also adds the appointments to the Zustand `scheduleStore`, where it will overwrite 
   * any existing appointment with the same ID.
   * ---
   * @param options 
   *  An optional object with options to filter the appointments.
   * @param {number} options.weekNumber
   *  The week number to fetch the saved schedule for. If not specified, the current week is used.
   * @param {'scannable' | 'non_scannable' | 'all'} filter.type 
   *  The type of appointments to retrieve. Can be `scannable`, `non-scannable`, or `all`.

   * ---
   * @returns {Promise<Appointment[]>} A promise that resolves to an array of appointments.
   */
  retrieveAppointments: (options?: {
    weekNumber?: number,
    type?: 'scannable' | 'non-scannable' | 'all',
  }) => Promise<Appointment[]>,

  /**
   * Retrieves a specific appointment from the database by its ID.
   * Throws an error if the appointment does not exist.
   * Also adds the appointment to the Zustand `scheduleStore`, where it will overwrite 
   * any existing appointment with the same ID.
   * 
   * ---
   * @param {string} id - The ID of the appointment to retrieve.
   * @throws {TRPCError} - Throws a TRPCError with code `NOT_FOUND` if the appointment does not exist.
   * @returns {Promise<Appointment>} - A promise that resolves to the appointment with the specified ID.
   */
  retrieveOneById: (id: string) => Promise<Appointment>,

  /**
   * Adds the authenticated user to a specific appointment, using the specified invite code.
   * Throws an error if the invite code is invalid or the user is already registered for the appointment.
   * Also adds the appointment to the Zustand `scheduleStore`.
   * 
   * ---
   * @param {string} inviteCode The invite code for the specific appointment.
   * @throws {TRPCError} - Throws a TRPCError if the invite request is fails.
   * @returns {Promise<Appointment>} A promise that resolves to the appointment the user was added to.
   */
  addWithInviteCode: (inviteCode: string) => Promise<Appointment>,

  /**
   * Calculates the status of an appointment, based on the current time, appointment times 
   * and whether the appointment is cancelled or not. This function can be called inside
   * `useTimedMemo` to automatically update the UI when the appointment status changes.
   * 
   * ---
   * @param {Appointment} appointment The appointment to get the status for.
   * @returns {AppointmentStatus} Returns the status of the appointment.
   */
  getStatus: (appointment: Appointment) => AppointmentStatus,

  /**
   * A function that checks if an appointment is scannable. It does so by
   * checking the following conditions:
   * 
   * 1. The appointment has at least one registration.
   * 2. The appointment is not cancelled or expired.
   * 
   * ---
   * @param {Appointment} appointment The appointment to check.
   * @returns {boolean} `true` if the appointment is scannable, `false` otherwise.
   */
  isScannable: (appointment: Appointment) => boolean,

  /**
   * Retrieves all scannable appointments, both from the cache and the database.
   * When fetching from the database, the internal `retrieveAppointments` method
   * is used, so that new appointments are also added to the cache.
   * 
   * ---
   * @param {string} source The source to fetch the scannable appointments from. Can be `cache`, `fetch`, or `both`.
   * @returns {Promise<Appointment[]>} A promise that resolves to an array of scannable appointments.
   */
  getAllScannable: (source: 'cache' | 'fetch' | 'both') => Promise<Array<Appointment>> 

}

export abstract class ScheduleProvider implements IScheduleProvider {

  /**
   * Fetches the schedule for a user from the database. Also includes non-scannable appointments.
   * 
   * ---
   * @param options 
   *  An optional object with options to filter the appointments.
   * @param {number} options.weekNumber
   *  The week number to fetch the saved schedule for. If not specified, the current week is used.
   * @param {'scannable' | 'non_scannable' | 'all'} filter.type 
   *  The type of appointments to retrieve. Can be `scannable`, `non-scannable`, or `all`.
   * @param {Appointment['status']} filter.status 
   *  The status of the appointments to retrieve. Adheres to the `Appointment['status']` type.
   * ---
   * @returns {Promise<Appointment[]>} A promise that resolves to an array of appointments.
   */
  public retrieveAppointments: IScheduleProvider['retrieveAppointments'] = async (options) => {
    
    /**
     * This creates a range from 6 hours, with the current time
     * as the center of the range, To optimize the query for scannable
     * and non-scannable appointments, this is used as an estimate
     * to filter on the start end end times.
     * 
     * When you want to increase the likelihood of finding ALL scannable
     * appointments (and catch more edge cases), you can increase this range.
     * A smaller range will increase the amount of scannable appointments 
     * that are missed in the filter, so it's a trade-off between optimizing
     * document reads and making our data as pure as possible. 
     * 
     * **Notice:** This range is defined in hours.
     */
    const ESTIMATE_HOUR_RANGE = {

      /**
       * The amount that is reduced from the current time to get the start of the range.
       * Uses the `moment.duration` function to create readable code.
       */
      start: moment.duration(3, 'hours').asMilliseconds(),

      /**
       * The amount that is added to the current time to get the end of the range.
       * Uses the `moment.duration` function to create readable code.
       */
      end: moment.duration(3, 'hours').asMilliseconds()
    }

    const filterType = options?.type ?? 'all';

    const weekNumber = options?.weekNumber ?? moment().isoWeek();
    const now = moment();
    const weekStart = moment().week(weekNumber).startOf('week').toISOString();
    const weekEnd = moment().week(weekNumber).endOf('week').toISOString();

    /**
     * The time window start is set to the current time minus the range hours.
     * This is an estimate for a range that defines appointments that are
     * potentially scannable.
     */
    const scannableStart = now.clone().subtract(ESTIMATE_HOUR_RANGE.start, 'milliseconds').toISOString();
    
    /**
     * The time window end is set to the current time plus the range hours.
     * This is an estimate for a range that defines appointments that are
     * potentially scannable.
     */
    const scannableEnd = now.clone().add(ESTIMATE_HOUR_RANGE.end, 'milliseconds').toISOString();

    const session = await getSession()
    if(!session?.user) {
      console.error('No user session found when trying to fetch appointments.')
      return []
    }

    const appointmentsRef = collection(firestore, 'appointments');
    let appointmentsQuery: Query<DocumentData>;

    switch(filterType) {
      case 'scannable': {
        appointmentsQuery = query(appointmentsRef,
          where('time.start', '>', scannableStart),
          where('time.start', '<', scannableEnd)
        );
        break;
      }
      case 'non-scannable': {
        appointmentsQuery = query(appointmentsRef,
          where('time.start', '>', weekStart),
          where('time.start', '<', scannableStart),
          where('time.start', '>', scannableEnd),
          where('time.start', '<', weekEnd),
        );
        break;
      }
      case 'all':
      default:
        appointmentsQuery = query(appointmentsRef,
          where('time.start', '>', weekStart),
          where('time.start', '<', weekEnd),
        );
        break;
    }

    const appointmentsSnapshot = await getDocs(appointmentsQuery);
    if (appointmentsSnapshot.empty) return [];

    // Map the documents to Appointment objects
    const appointments = appointmentsSnapshot.docs
      .map(doc => doc.data() as Appointment)
      .filter(aptmt => Object.keys(aptmt.employees).includes(session.user.email))
      .sort((a, b) => moment(a.time.start).diff(moment(b.time.start)));

    // Add the appointments to the store
    this.updateCache(weekNumber, appointments, 'fetch');

    return appointments.filter((aptmt) => {
      if(filterType === 'scannable') return this.isScannable(aptmt);
      else if(filterType === 'non-scannable') return !this.isScannable(aptmt);
      else return true;
    });
  }

  /**
   * Retrieves a specific appointment from the database by its ID.
   * Throws an error if the appointment does not exist.
   * 
   * ---
   * @param {string} id - The ID of the appointment to retrieve.
   * @throws {TRPCError} - Throws a TRPCError with code `NOT_FOUND` if the appointment does not exist.
   * @returns {Promise<Appointment>} - A promise that resolves to the appointment with the specified ID.
   */
  public retrieveOneById: IScheduleProvider['retrieveOneById'] = async (id) => {

    const appointmentRef = doc(firestore, 'appointments', id);
    const appointmentSnapshot = await getDoc(appointmentRef);

    if(!appointmentSnapshot.exists() || !appointmentSnapshot.data()) {
      throw new TRPCError({
        code: 'NOT_FOUND',
        message: 'De opgegeven afspraak bestaat niet.'
      })
    }

    //#region Add appointment to the store

      const oldAppointments = useScheduleStore.getState().appointments;

      /**
       * Add the new appointment to the store, ensuring that the new appointment
       * takes precedence over the old one, if there's a conflict.
       */
      useScheduleStore.setState({ 
        appointments: [
          ...new Map([...oldAppointments, appointmentSnapshot.data() as Appointment]
            .map(aptmt => [aptmt.id, aptmt]))
            .values()
        ]
      });

    //#endregion

    return appointmentSnapshot.data() as Appointment;
  }

  /**
   * Adds the authenticated user to a specific appointment, using the specified invite code.
   * Throws an error if the invite code is invalid or the user is already registered for the appointment.
   * 
   * ---
   * @param {string} inviteCode The invite code for the specific appointment.
   * @throws {TRPCError} - Throws a TRPCError if the invite request is fails.
   * @returns {Promise<Appointment>} A promise that resolves to the appointment the user was added to.
   */
  public addWithInviteCode: IScheduleProvider['addWithInviteCode'] = async (inviteCode) => {

    if(!LESSON_INVITE_CODE.isValid(inviteCode.toUpperCase())) {
      throw new TRPCError({
        code: 'BAD_REQUEST',
        message: 'De opgegeven deelnamecode is ongeldig.'
      })
    }

    const appointment = await trpx.modules.schedule.addWithInviteCode.mutate({ inviteCode: inviteCode.toUpperCase() })
    
    //#region Add appointment to the store

      const oldAppointments = useScheduleStore.getState().appointments;

      /**
       * Add the new appointment to the store, ensuring that the new appointment
       * takes precedence over the old one, if there's a conflict.
       */
      useScheduleStore.setState({ 
        appointments: [
          ...new Map([...oldAppointments, appointment]
            .map(aptmt => [aptmt.id, aptmt]))
            .values()
        ]
      });

    //#endregion

    return appointment;
  }

  /**
   * Calculates the status of an appointment, based on the current time, appointment times 
   * and whether the appointment is cancelled or not. This function can be called inside
   * `useTimedMemo` to automatically update the UI when the appointment status changes.
   * 
   * ---
   * @param {Appointment} appointment The appointment to get the status for.
   * @returns {AppointmentStatus} Returns the status of the appointment.
   */
  public getStatus: IScheduleProvider['getStatus'] = (appointment) => {

    const now = moment();
    const start = moment(appointment.time.start);
    const end = moment(appointment.time.end);
    const prefixedStart = start.clone().subtract(appointment.time.prefix_mins, 'minutes');
    const suffixedEnd = end.clone().add(appointment.time.suffix_mins, 'minutes');

    if(appointment.cancelled) return 'cancelled';
    else if(now.isBefore(prefixedStart)) return 'upcoming';
    else if(now.isBetween(prefixedStart, start, 'seconds', '[]')) return 'prefix_time';
    else if(now.isBetween(start, end, 'seconds', '[]')) return 'active';
    else if(now.isBetween(end, suffixedEnd, 'seconds', '[]')) return 'suffix_time';
    else return 'expired';
  }

  /**
   * A function that checks if an appointment is scannable. If an appointment 
   * is scannable, it will automatically show up in the Scan UI and the user
   * will be redirected to it when they activate the specific appointment. If
   * the appointment is not scannable (anymore), it will not show up in the scan UI.
   * 
   * The following conditions are checked to determine if an appointment is scannable:
   * 1. The appointment has at least one registration.
   * 2. The appointment status is either `prefix_time`, `active` or `suffix_time`.
   * 
   * ---
   * @param {Appointment} appointment The appointment to check.
   * @returns {boolean} `true` if the appointment is scannable, `false` otherwise.
   */
  public isScannable: IScheduleProvider['isScannable'] = (appointment) => {
    const scannableStatuses: AppointmentStatus[] = ['prefix_time', 'active', 'suffix_time'];
    const status = this.getStatus(appointment);
    const hasAttendance = Object.entries(appointment.attendance.registrations).length > 0;

    return hasAttendance && scannableStatuses.includes(status);
  }

  /**
   * Retrieves all scannable appointments, both from the cache and the database.
   * When fetching from the database, the internal `retrieveAppointments` method
   * is used, so that new appointments are also added to the cache.
   * 
   * ---
   * @returns {Promise<Appointment[]>} A promise that resolves to an array of scannable appointments.
   */
  public getAllScannable: IScheduleProvider['getAllScannable'] = async (source) => {
    switch(source) {

      /**
       * Fetch all appointments from the cache by filtering the appointments.
       */
      case 'cache': {
        return useScheduleStore.getState().appointments.filter(aptmt => this.isScannable(aptmt));
      }

      /**
       * Fetch and filter from the database.
       */
      case 'fetch': {
        const appointments = await this.retrieveAppointments({ type: 'scannable' });
        console.log(`Fetched ${appointments.length} scannable appointments from the database.`);
        return appointments
      }

      /**
       * A combination of both of the above.
       */
      case 'both': {
        const cachedAppointments = useScheduleStore.getState().appointments.filter(aptmt => this.isScannable(aptmt));
        const fetchedAppointments = await this.retrieveAppointments({ type: 'scannable' });

        console.log(`Fetched ${fetchedAppointments.length} scannable appointments from the database.`);
        return [
          ...new Map([...cachedAppointments, ...fetchedAppointments]
            .map(aptmt => [aptmt.id, aptmt]))
            .values()
        ]
      }
    }
  }

  //#region Methods and properties for FIFO caching and the Zustand store

    /**
     * Defines the intervals that are used for cached schedule fetching and synchronisation.
     * These intervals are defined as read-only values, to discourage changing them at runtime
     * on the client side. The intervals are used to determine the minimum times between different
     * schedule operations, such as fetching, syncing, and timer intervals.
     * 
     * @readonly
     */
    public readonly intervals = {

      /**
       * Defines the minimum interval for schedule synchronization, in the current week.
       * The current week needs to be updated more regularly than the other weeks, to ensure
       * that the user has the most recent schedule available. A good default value is 15 minutes.
       * 
       * **Notice:** This interval is defined in milliseconds.
       * @readonly
       */
      currentWeekSync: moment.duration(20, 'minutes').asMilliseconds(),

      /**
       * Defines the minimum interval for fetching the appointments for the current week
       * from the database. This interval is used to prevent unnecessary database reads.
       * The current week needs to be updated more regularly than the other weeks, to ensure
       * that the user has the most recent schedule available. A good default value is 5 minutes.
       * 
       * **Notice 1:** This interval is defined in milliseconds.
       * 
       * **Notice 2:** This interval should be higher than the `currentWeekSync` interval.
       * @readonly
       */
      currentWeekFetch: moment.duration(7.5, 'minutes').asMilliseconds(),

      /**
       * Defines the minimum interval for schedule synchronization, in other weeks than the current week.
       * The other weeks need to be updated less regularly than the current week, to prevent unnecessary
       * API calls and database writes. A good default value is 1 hour.
       * 
       * **Notice:** This interval is defined in milliseconds.
       * @readonly
       */
      otherWeekSync: moment.duration(90, 'minutes').asMilliseconds(),

      /**
       * Defines the minimum interval for fetching the appointments for other weeks than the current week.
       * This interval is used to prevent unnecessary database reads. The other weeks need to be updated
       * less regularly than the current week, to prevent unnecessary API calls and database writes.
       * A good default value is 20 minutes.
       * 
       * **Notice 1:** This interval is defined in milliseconds.
       * 
       * **Notice 2:** This interval should be higher than the `otherWeekSync` interval.
       * @readonly
       */
      otherWeekFetch: moment.duration(30, 'minutes').asMilliseconds(),

      /**
       * An internal client-side `setInterval`-timer is used to automatically sync and fetch the schedule
       * for the current week. For any other weeks, the `cachedSync` and `cachedFetch` methods are ran
       * only when neccessary. This variable defines the interval / delay for the client-side timer.
       * 
       * **Notice:** This interval is defined in milliseconds.
       * @readonly
       */
      timer: moment.duration(1, 'minute').asMilliseconds()
    }

    /**
     * Updates the client-side Zustand cache with the appointments for a specific week,
     * including the execution time of the fetch or sync operation. This method is called
     * when the schedule is fetched or synced through this singleton provider. This ensures
     * that even when the schedule is fetched or synced from a non-cache operation, the cache
     * will still be updated with the new appointments and execution times.
     * 
     * **Notice:** FIFO cache clearing is also applied in this method, to maintain storage
     * efficiency and prevent memory leaks. A maximum of 10 weeks of data is kept within the cache,
     * excluding the current week. The current week is always kept in the cache.
     * 
     * **Warning:** This method does not manage / check if a cache update is necessary. That responsibility
     * is left outside of this function. In other words, this function does not check if the appointments
     * are still up-to-date, it only updates the cache with the new appointments and execution times.
     * 
     * ---
     * @param {number} week - The week number to update the cache for. 
     * @param {Array<Appointment>} appointments - The appointments to merge into the cache. These appointments will overwrite any existing appointments with the same ID.
     * @param {'sync' | 'fetch'} type - The type of operation that triggered the cache update. Can be `sync` or `fetch`.
     */
    protected updateCache = (week: number, appointments: Appointment[], type: 'sync' | 'fetch') => {
      
      /**
       * Maximum number of weeks to keep in the cache, to prevent memory leaks.
       * Current week is NEVER included in FIFO clearing. The current week is always kept in the cache.
       */
      const fifoMaxWeeks = 10;
      const currentWeek = moment().isoWeek();

      const state = useScheduleStore.getState();
      const oldAppointments = state.appointments;
      const oldExecutions = state.sync.executions;

      /**
       * Determine the weeks to keep based on FIFO logic,
       * excluding the current week from the FIFO clearing.
       */
      const weeksToKeep = Object.keys(oldExecutions)
        .map(Number)
        .filter(week => oldExecutions[week].lastSync || oldExecutions[week].lastFetch)
        .sort((a, b) => {

          /**
           * Gets the most recent action time between lastSync and lastFetch for a given week.
           * @param {number} week The week number to check.
           * @returns {string} The most recent ISO8601 date string of the action, or an empty string if none.
           */
          const getLastActionTime = (week: number) => {
            const lastSync = oldExecutions[week]?.lastSync;
            const lastFetch = oldExecutions[week]?.lastFetch;
            
            // Return the most recent time or an empty string if neither exist
            return lastSync && lastFetch ? (moment(lastSync).isAfter(lastFetch) ? lastSync : lastFetch) 
              : lastSync || lastFetch || "";
          };

          // Use the getLastActionTime function to compare and determine the newer week
          return getLastActionTime(b).localeCompare(getLastActionTime(a));
        })
        .slice(0, fifoMaxWeeks)

      // Add the newly added week to the weeks to keep
      if(!weeksToKeep.includes(week)) weeksToKeep.push(week);
      
      // Add the current week to the weeks to keep
      if(!weeksToKeep.includes(currentWeek)) weeksToKeep.push(currentWeek);

      /**
       * Filter the old appointments to only keep the appointments for the weeks
       * that are still in the FIFO cache after clearing, or the new week.
       */
      const filteredAppointments = oldAppointments.filter(aptmt => {
        const aptmtWeek = moment(aptmt.time.start).isoWeek();
        return weeksToKeep.includes(aptmtWeek) || aptmtWeek === week;
      })

      /**
       * Updates the executions with the new or existing data for the weeks to keep.
       */
      const updatedExecutions = weeksToKeep.reduce<typeof oldExecutions>((acc, wk) => {
        acc[wk] = wk === week ? {
          lastSync: type === 'sync' ? moment().toISOString() : oldExecutions[wk]?.lastSync ?? null,
          lastFetch: type === 'fetch' ? moment().toISOString() : oldExecutions[wk]?.lastFetch ?? null,
        } : oldExecutions[wk];
        return acc;
      }, {});

      /**
       * Update the Zustand store with the new appointments and executions.
       */
      useScheduleStore.setState({
        appointments: [
          ...new Map([...filteredAppointments, ...appointments]
            .map(aptmt => [aptmt.id, aptmt]))
            .values()
        ],
        sync: {
          ...state.sync,
          executions: updatedExecutions
        }
      });
    }

  //#endregion

  //#region Methods and properties to be implemented in derived classes

    /**
     * Synchronizes the schedule with the Skip Scanner database.
     * Internally, it calls the different private methods of the different schedule providers
     * to fetch the schedule, parse it, and sync it to the database.
     * ---
     * @param {number} weekNumber The week number to sync the schedule for. If not specified, the current week is used.
     * @throws {TRPCError} If the appointment synchronization fails.
     * @returns {Promise<Appointment[]>} A promise that resolves to all the appointments for that week, incl. newly synced appointments.
     */
    public abstract sync: IScheduleProvider['sync'];

  //#endregion
}