import React from "react";
import Editor, { type EditorProps } from "@monaco-editor/react";
import { omit } from "lodash";
import { twMerge } from "tailwind-merge";

type JSONEditorProps = Omit<EditorProps, 'defaultLanguage' | 'theme'> & {
  readOnly?: boolean;
  options?: Omit<EditorProps['options'], 'readOnly' | 'padding'>
};

/**
 * Creates and returns a Monoco Code Editor instance with JSON syntax highlighting.
 * For value, pass in a valid JSON string.
 * 
 * @returns {React.FC} The rendered JSON Editor. 
 */
export const JSONEditor:React.FC<JSONEditorProps> = ({ readOnly = true, options, className, ...props }) => {
  return (
    <div className={twMerge('!rounded-lg !overflow-hidden', className)}>
      <Editor
        {...omit(props, 'className', 'options')}
        defaultLanguage='json'
        theme='vs-dark'
        options={{ 
          readOnly,
          padding: { top: 16, bottom: 8 },
          ...options
        }}
      />
    </div>
  )
}