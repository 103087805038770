import type { Appointment, Registration } from '@skip-scanner/toolkit/database'
import { ZermeloScheduleProvider } from './zermelo'
import { IScheduleProvider } from './base'
import { MockScheduleProvider } from './mock';
import { getModuleValue } from 'config/util'
import { getSession } from 'next-auth/react';
import { toast } from '@skip-scanner/ui';
import { TRPCError } from '@trpc/server';

/**
 * Creates the schedule provider singleton. Based on the module configuration of the tenant,
 * it will create and instantiate the correct schedule provider.
 * @returns {IScheduleProvider} The schedule provider singleton.
 */
const createScheduleProvider = async ():Promise<IScheduleProvider> => {

  const activeScheduleProvider = await getModuleValue('schedule')

  switch (activeScheduleProvider) {
    case 'zermelo':
      return new ZermeloScheduleProvider();
    case 'mock':
      return new MockScheduleProvider();
    default:
      throw new Error(`The schedule provider "${activeScheduleProvider}" is not supported.`);
  }
}

/**
 * ### Module: `schedule`
 * 
 * The schedule module is responsible for syncing an external schedule from a user to the Skip Scanner database.
 * It also manages different appointments and makes it possible to access and edit these before the user scans them.
 * 
 * The scheduleProvider factory automatically infers which schedule provider to use based on the module configuration.
 * Currently, the following schedule providers are supported:
 * 
 * - `zermelo`: Fetches the schedule from a school-specific Zermelo API endpoint and syncs it to the Skip Scanner database.
 * - `mock`: Creates a fake and randomized schedule with default values that are provided by the **Mock** class.
 */
export const schedule = await createScheduleProvider();