import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { trpx } from 'lib/trpc'
import { AppRouter } from 'server/routers/_app'
import { TRPCError, inferProcedureInput } from '@trpc/server'
import moment from 'moment'
import type { MigrationsHistory } from '@skip-scanner/toolkit/types/util'
import { toast } from '@skip-scanner/ui'
import { lowerCase } from 'lodash'

type Model = {
  name: string,
  latest: string,
  migrations: Array<{
    created: string;
    id: string;
  }>
}

type CustomPatchUpInput = inferProcedureInput<AppRouter['sksc']['database']['customPatchUp']>;

type DatabaseState = {

  /**
   * Information about different models. Gets fetched from the `migration_history.json` file on the server.
   */
  models: Array<Model> | null,
  
  /**
   * Collected integrity values for different models.
   */
  integrityValues: Record<string, Array<{
    migration_id: string;
    docs: number;
  }>>,

  /**
   * Raw JSON string that stores the results of the endpoints.
   * For volatile display in client-side monaco editor. For large datasets,
   * JSON is the most efficient insights option.
   */
  jsonResults: string,

  /**
   * Retrieves all existing models and adds them inside an array.
   */
  retrieveModels: () => Promise<void>,

  /**
   * Fetches an overview of how many documents adhere to the different migrations of the model.
   * This can be used to index how many documents are up-to-date and if any patch-up scripts need to be
   * executed. 
   * @param modelName - The name of the model to fetch integrity values for.
   */
  getIntegrityValues: (modelName: string) => Promise<void>,

  verifyModelIndexes: (modelName: string, migrationId: string) => Promise<void>,

  /**
   * **DO NOT EDIT**: Client-side code is implementation agnostic. It is only used to activate the patch-up and parse the response. 
   * 
   * This endpoint can be customized on-demand to implement custom alterations of documents in a specific model
   * (and optional migrationId). When a specific set of documents needs to be migrated, it is better and faster to
   * implement custom code for it than design an extensive UI that is capable of handling all the edge cases.
   * 
   * This endpoint supplies specific variables that can be used to quickly iterate over all provided documents and edit them 
   * when neccessary.
   * 
   * @param {string} modelName The name of the model to edit in the database.
   * @param {object} options Optional filter options for the call.
   * @param {'all' | 'indexed' | 'not_indexed' | 'migration_id'} options.filter The filter to specify which documents need to be fetched.
   * @param {string} options.migrationId The migration ID if options.filter == `migration_id`.
   * @returns {any} The result of the custom patch-up script.
   */
  customPatchUp: (input: CustomPatchUpInput) => Promise<void>

}

/**
 * This store gives access to database management inside the Skip Scanner application. 
 * Caches data locally because large and expensive database queries can be called through 
 * this interface.
 * 
 * **Notice:** Only for users with the `sksc-employee` role enabled.
 */
export const useDatabaseStore = create<DatabaseState>()(
  persist(
    (set, get) => ({

      models: null,
      integrityValues: {},
      jsonResults: JSON.stringify({
        message: 'make a request and you will see the results here.'
      }, undefined, 2),

      retrieveModels: async () => {
        try {
          const models = await trpx.sksc.database.retrieveModels.query();
          set({ models })
        }
        catch(err) {
          const error = err as TRPCError
          toast(error.message, { type: 'error'})
          set({ models: null })
        }
      },

      getIntegrityValues: async (modelName) => {
        try {
          
          let integrityCollection = get().integrityValues

          const integrityVals = await trpx.sksc.database.getIntegrityValues.query({ modelName })
          integrityCollection[lowerCase(modelName)] = integrityVals

          set({ integrityValues: integrityCollection })
        }
        catch(err) {
          const error = err as TRPCError
          toast(error.message, { type: 'error'})
        }
      },

      verifyModelIndexes: async (modelName, migrationId) => {
        try {
          const result = await trpx.sksc.database.verifyModelIndexes.mutate({ modelName, migrationId })
          const jsonResults = JSON.stringify(result, undefined, 2)

          set({ jsonResults })
        }
        catch(err) {
          const error = err as TRPCError
          toast(error.message, { type: 'error'})
        }
      },

      customPatchUp: async (input) => {
        try {
          const result = await trpx.sksc.database.customPatchUp.mutate(input)
          const jsonResults = JSON.stringify(result, undefined, 2)
  
          set({ jsonResults })
        }
        catch(err) {
          const error = err as TRPCError
          toast(error.message, { type: 'error'})
        }
      }

    }),
    {
      name: 'zustand:sksc-database-storage',
      storage: createJSONStorage(() => 
        sessionStorage
      )
    }
  )
)