import React, { useId } from "react";
import { motion, type SVGMotionProps } from "framer-motion";
import { omit } from "lodash";

type SpinnerComponentProps = Omit<SVGMotionProps<SVGSVGElement>, 'viewBox' | 'fill'>

/**
 * Generates an SVG-native spinner component. The spinner consists of
 * 2 rounded rectangles that move in a circular motion. Can be styled like 
 * any normal SVG element.
 * 
 * @returns {React.FC} The Spinner component.
 */
export const Spinner: React.FC<SpinnerComponentProps> = ({ ...props }) => {

  const uniqueId = useId();
  const generateId = (baseId: string) => `${baseId}_${uniqueId}`;
  
  return (
    <motion.svg
      viewBox="0 0 24 24"
      fill="currentColor"
      className={props.className}
      {...omit(props, 'className')}
    >
      <rect x="1" y="1" rx="1" width="10" height="10">
        <animate id={generateId("spinner_FFyM")} begin={`0;${generateId("spinner_HDCY")}.end`} attributeName="x" dur="0.2s" values="1;13" fill="freeze" />
        <animate id={generateId("spinner_AIvE")} begin={`${generateId("spinner_1FwE")}.end`} attributeName="y" dur="0.2s" values="1;13" fill="freeze" />
        <animate id={generateId("spinner_wWCL")} begin={`${generateId("spinner_gH4o")}.end`} attributeName="x" dur="0.2s" values="13;1" fill="freeze" />
        <animate id={generateId("spinner_S3Gg")} begin={`${generateId("spinner_Q0bx")}.end`} attributeName="y" dur="0.2s" values="13;1" fill="freeze" />
      </rect>
      <rect x="1" y="13" rx="1" width="10" height="10">
        <animate id={generateId("spinner_1FwE")} begin={`${generateId("spinner_FFyM")}.end`} attributeName="y" dur="0.2s" values="13;1" fill="freeze" />
        <animate id={generateId("spinner_gH4o")} begin={`${generateId("spinner_AIvE")}.end`} attributeName="x" dur="0.2s" values="1;13" fill="freeze" />
        <animate id={generateId("spinner_Q0bx")} begin={`${generateId("spinner_wWCL")}.end`} attributeName="y" dur="0.2s" values="1;13" fill="freeze" />
        <animate id={generateId("spinner_HDCY")} begin={`${generateId("spinner_S3Gg")}.end`} attributeName="x" dur="0.2s" values="13;1" fill="freeze" />
      </rect>
    </motion.svg>
  )
}