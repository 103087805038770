import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { getSession } from 'next-auth/react'
import moment from 'moment'
import { toast, confirm } from '@skip-scanner/ui'
import { trpx } from 'lib/trpc'
import { merge, pick } from 'lodash'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { env } from 'lib/env.mjs'

import type { Appointment, Registration, Student } from '@skip-scanner/toolkit/database'
import type { Scan, ScanSummary } from "@skip-scanner/toolkit/database/models/registration";

import { firestore } from '@skip-scanner/toolkit/firebase/client'
import { 
  doc, 
  collection,
  query,
  where,
  getDoc,
  getDocs,
  writeBatch,
  updateDoc,
  arrayUnion,
  type DocumentReference, 
  type DocumentData,
  type DocumentSnapshot,
  type Query 
} from 'firebase/firestore'
import { schedule } from 'lib/modules'

type AppointmentStore = {

  /**
   * The controller for all unresolved scans in the store. Includes the `cache` property
   * which is an array of unresolved scans, and the `push` and `pop` methods to add and
   * remove scans from the cache.
   */
  unresolvedScans: {
    
    /**
     * The cache of unresolved scans, which are not yet processed.
     */
    cache: ScanSummary[],

    /**
     * Pushes 1 or more unresolved scans into the cache, for later processing.
     * 
     * ---
     * @param {ScanSummary | ScanSummary[]} scans The scan(s) to push into the cache. 
     * @returns {void} Returns nothing.
     */
    push: (scans: ScanSummary | ScanSummary[]) => void,

    /**
     * When this function is called, it looks into the cache and checks if there are any unresolved
     * scans that match a student ID in the appointment registrations. If so, it will pop the scan 
     * from the cache and add it to the database as a resolved scan.
     * ---
     * @param {Appointment} appointment The appointment to pop the unresolved scan for.
     * @param {boolean} silent Whether to show a toast message when the scan is popped. Defaults to `true`.
     * @returns {Promise<void>} Returns a promise that resolves when the scan is popped.
     */
    pop: (appointment: Appointment, silent?: boolean) => Promise<void>
  },

  /**
   * Controls different parts of the registration process, such as adding a scan, removing the last scan,
   * and editing the context of a registration. 
   */
  registrations: {

    /**
     * Adds a new scan moment for a student registration to a specific appointment.
     * 
     * @param {ScanSummary} scan The scan to add.
     * @param {Appointment} appointment The appointment to add the scan to.
     * @param {boolean} silent Whether to show a toast message when the scan is added. Defaults to `true`.
     * @returns {Promise<void>} A promise that resolves when the scan is added.
     */
    addScan: (scan: ScanSummary, appointment: Appointment, silent?: boolean) => Promise<void>,
    
    /**
     * Removes the last scan for a specific student registration in an appointment. 
     * Only the last scan can be removed, because else you can accidentally create gaps in the registration 
     * scans array.
     * 
     * @param {string} studentID The ID of the student to remove the last scan for.
     * @param {Appointment} appointment The appointment to remove the last scan from.
     * @param {boolean} silent Whether to show a toast message when the last scan is removed. Defaults to `true`.
     * @returns {Promise<void>} A promise that resolves when the last scan is removed.
     */
    removeLastScan: (studentID: string, appointment: Appointment, silent?: boolean) => Promise<void>,

    /**
     * This function will try to edit a context for a specific scan or registration.
     * 
     * @param {string | null} newCtx The new context to set for the registration. If `null`, the context will be removed.
     * @param {string} studentID The ID of the student to edit the context for.
     * @param {Appointment} appointment The appointment to edit the context in.
     * @param {Optional<{time: string}>} scan If the scan time is specified, it will edit the context of the scan. Else, it will edit the context of the base registration.
     * @param {Optional<boolean>} silent Whether to show a toast message when the context is edited. Defaults to `true`. 
     * @returns {Promise<void>} A promise that resolves when the context is edited.
     */
    editCtx: ( newCtx: string | null, studentID: string, appointment: Appointment, scan?: { time: string }, silent?: boolean ) => Promise<void>,

    /**
     * This function checks if a specified student is in the optional students list of the appointment.
     * If so, it will add the student to the appointment and remove it from the optional students list.
     * Will throw an error if the student is not in the optional students list or if the 
     * student does not exist in the database.
     * ---
     * @param {string} studentID - The ID of the student to add to the appointment.
     * @param {Appointment} appointment - The appointment to retrieve the optional students from and edit. 
     * @param {boolean} silent - Whether to show a toast message when the student is added. Defaults to `false`.
     * @returns {Promise<void>} Returns a promise that resolves when the student is added to the appointment.
     * ---
     * @example
     * ```typescript
     * const { registrations, unresolvedScans } = useAppointmentStore();
     * 
     * // Because you can check if the student is in the optional students list, you can use a confirm dialog.
     * if(await confirm('Are you sure you want to add this student to the appointment?')) {
     *   await registrations.addFromOptionals(studentID, appointment);
     * }
     * else {
     *   await unresolvedScans.push(scan)
     * }
     * ```
     */
    addFromOptionals: (studentID: string, appointment: Appointment, silent?: boolean) => Promise<void>,

  },

  /**
   * This function is immediately called when the appointment status changes from `suffix_time` to `expired`.
   * It will finalize the appointment, which includes actions like parsing the remaining students that have not checked
   * out yet. 
   * ---
   * @param {Appointment} appointment The appointment to finalize. 
   * @returns {Promise<void>} A promise that resolves when the appointment is successfully finalized.
   */
  finalize: (appointment: Appointment) => Promise<void>

}

export const useAppointmentStore = create<AppointmentStore>()(
  persist(
    immer(
      (set, get) => ({

        unresolvedScans: {
          cache: [],
          pop: async (appointment, silent = true) => {

            const { 
              unresolvedScans: { cache }, 
              registrations: { addScan } 
            } = get();

            // Create a map of the registrations for easy access
            const registrations = new Map(Object.entries(appointment.attendance.registrations));
            
            // Filter the cache to find unresolved scans that match the registrations
            const matchingScans = cache.filter(scan => registrations.has(scan.studentID));

            // Remaining unresolved scans becomes a new array, that has the matching scans removed
            const newCache = cache.filter(scan => !matchingScans.includes(scan));

            // Update the cache with the new array
            set(state => { state.unresolvedScans.cache = newCache })

            // Add the matching scans to the database
            await Promise.all(matchingScans.map(async scan => {
              await addScan(scan, appointment, true);
              console.log(`Popped scan from cache for student ${scan.studentID}, with time ${scan.time}.`);
            }))

            if(!silent && matchingScans.length > 0) {
              toast(`${matchingScans.length} scans uit cache geüpdated.`, { type: 'success' })
            }

          },
          push: (scans) => {
            const cache = get().unresolvedScans.cache;
            const newScans = Array.isArray(scans) ? scans : [scans];
            set(state => { state.unresolvedScans.cache = [...cache, ...newScans] })
          } 
        },

        registrations: {
          addScan: async (_scan, appointment, silent = true) => {

            /**
             * The scan variable that can be changed / updated when certain conditions are met.
             * Includes the optional `ctx` property, which can be filled with the following values in the context of a new scan:
             * 
             * 1. "Automatisch toegevoegd; leerling probeerde uit te checken na uitlooptijd."
             */
            let scan:ScanSummary & { ctx?: string } = { ..._scan }
            const registrations = new Map(Object.entries(appointment.attendance.registrations));
            const appointmentStatus = schedule.getStatus(appointment);

            // The scan is for a registration for the current active lesson. They always are a priority
            if(registrations.has(scan.studentID)) {
              
              const registration = registrations.get(scan.studentID) as Registration<'appointment'>
              const nextScanType = registration.scans.at(-1)?.type == 'in' ? 'out' : 'in'
              
              // Calculate if scan can be saved
              switch(appointmentStatus) {
                case 'upcoming':
                case 'cancelled':
                case 'expired': {
                  // The appointment is not active, so the scan can not be added.
                  return;
                }

                case 'suffix_time': {
                  if(nextScanType == 'in') {
                    toast(`Leerling ${registration.student.personal.firstName} ${registration.student.personal.lastName} heeft willen inchecken in de uitlooptijd. Scanmoment wordt genegeerd.`, { type: 'warning' })
                    return
                  }
                  else break;
                }

                default: break;                 
              }

              //#region Write lesson + student registrations to Firestore

                const batch = writeBatch(firestore)
                const appointmentRef = doc(firestore, 'appointments', appointment.id)
                const studentRef = doc(firestore, 'students', scan.studentID, 'registrations', appointment.id)

                /**
                 * Creates a new array with the new scan added to the end of the array.
                 */
                const updatedScans:Array<Scan> = [
                  ...registration.scans, 
                  { 
                    type: nextScanType, 
                    time: scan.time, 
                    ctx: scan.ctx ?? null,
                  }
                ]

                /**
                 * Only update the registration scans array for the specific student, to minimize the
                 * ingress of data to the database and to prevent accidental overwrites of the entire doc.
                 */
                batch.update(appointmentRef, {
                  [`attendance.registrations.${scan.studentID}.scans`]: updatedScans,
                })

                /**
                 * The student document CAN be completely updated however, since we can not be sure that
                 * the student registration is completely up-to-date at any time.
                 */
                const studentRegistration:Registration<'student'> = {
                  appointment: pick(appointment, ['employees', 'id', 'locations', 'time', 'type', 'subjects']),
                  scans: updatedScans,
                  ctx: registration.ctx,
                  id: registration.id,
                  student: undefined,
                  _: {
                    model: 'Registration',
                    version: '1706558935_automatic_startup_and_sync'
                  }
                } 
                batch.set(studentRef, studentRegistration)

                try {
                  await batch.commit()
                  if(!silent) toast(`${nextScanType == 'in' ? 'Uit' : 'In'}checkmoment succesvol toegevoegd.`, { type: 'success' })
                }
                catch(err) {
                  console.error(err);
                  if(!silent) toast(`Er is een fout opgetreden bij het toevoegen van een ${nextScanType == 'in' ? 'uit' : 'in'}checkmoment.`, { type: 'error' })
                }

              //#endregion
            }

            // The scan is for a student that is in the optional students list
            else if(appointment.attendance.optionals.includes(scan.studentID)) {
              
              const studentRef = doc(firestore, 'students', scan.studentID)
              const studentDoc = await getDoc(studentRef)

              // If no matching student is found in the optionals list, return
              if(!studentDoc.exists()) {
                console.error(`Student ${scan.studentID} is niet gevonden in de database.`)
                get().unresolvedScans.push(scan);
                return
              }

              const student = studentDoc.data() as Student;
              const fullStudentName = `${student.personal.firstName} ${student.personal.prefix ?? ''} ${student.personal.lastName}`.replace('  ', ' ');

              if(await confirm(
                `De leerling ${fullStudentName} heeft geprobeerd in te checken.`,
                `Deze leerling is zich vergeten aan te melden voor deze afspraak. Wilt u deze leerling toevoegen aan de huidige afspraak?`)) {
                
                await get().registrations.addFromOptionals(scan.studentID, appointment, false);
                await get().registrations.addScan(scan, appointment, false);
              }
              else get().unresolvedScans.push(scan);
            }

            // The scan is not for a registration for the active lesson. So write it to the cache.
            else get().unresolvedScans.push(scan)
          },
          removeLastScan: async (studentID, appointment, silent = false) => {

            const registrations = new Map(Object.entries(appointment.attendance.registrations));

            if(registrations.has(studentID)) {
              const registration = registrations.get(studentID) as Registration<'appointment'>
              const lastScan = registration.scans.at(-1)

              //#region Wait for user confirmation

              if(!await confirm(
                `Weet u zeker dat u dit ${lastScan?.type == 'in' ? 'in' : 'uit'}checkmoment wilt verwijderen?`,
                'Deze verandering kan niet ongedaan gemaakt worden. De huidige scan gaat hiermee ook verloren.'
              )) return

              //#endregion

              //#region Update appointment + student registrations to Firestore

              const batch = writeBatch(firestore)
              const appointmentRef = doc(firestore, 'appointments', appointment.id)
              const studentRef = doc(firestore, 'students', studentID, 'registrations', appointment.id)

              /**
               * Creates a new array with every item but the last one.
               */
              const updatedScans:Array<Scan> = registration.scans.slice(0, -1)

              batch.update(appointmentRef, {
                [`attendance.registrations.${studentID}.scans`]: updatedScans
              })

              /**
               * Because we can not be sure that, at any time, the student registration is
               * completely up-to-date, write everything we know from the client to the database.
               */
              const studentRegistration:Registration<'student'> = {
                _: {
                  model: 'Registration',
                  version: '1706558935_automatic_startup_and_sync'
                },
                id: `${studentID}:${appointment.id}`,
                ctx: registration.ctx,
                scans: updatedScans,
                appointment: pick(appointment, ['employees', 'id', 'locations', 'time', 'type', 'subjects']),
                student: undefined
              }
              batch.set(studentRef, studentRegistration)

              try {
                await batch.commit()
                if(!silent) toast(`Laatste ${lastScan?.type == 'in' ? 'in' : 'uit'}checkmoment succesvol verwijderd.`, { type: 'success' })
                return;
              }
              catch(err) {
                console.error(err)
                if(!silent) toast(`Er is een fout opgetreden bij het verwijderen van het laatste ${lastScan?.type == 'in' ? 'in' : 'uit'}checkmoment.`, { type: 'error' })
                return;
              }
              //#endregion
            }
  
          },
          editCtx: async (newCtx, studentID, appointment, scan, silent = true) => {

            const ctxLocation = scan ? `scan` : `appointment`;
            const registrations = new Map(Object.entries(appointment.attendance.registrations));
            const appointmentStatus = schedule.getStatus(appointment);

            if(appointmentStatus === 'upcoming' || appointmentStatus === 'cancelled') {
              toast(`Een verantwoording kan alleen toegevoegd worden aan een afspraak die actief of verlopen is.`, { type: 'warning' })
              return
            }

            if(registrations.has(studentID)) {

              /**
               * The current registration that needs to be edited with a new ctx.
               * The properties `appointment` and `student` are not used in this call,
               * so we don't need to worry about the Registrations `saveLocation` property. 
               */
              const registration = registrations.get(studentID) as Registration<'appointment'>;

              /**
               * This index is used to retrieve the specific index of the scan in the scan array.
               * If no scan object is specified, null is returned. Registrations can, for now
               * only be edited on existing scans.
               */
              const scanIndex = scan ? registration.scans.findIndex(s => s.time === scan.time) : null

              //#region Check if update can be done, or if 1 or 2 docs are missing

              const studentRef = doc(firestore, 'students', studentID, 'registrations', appointment.id)
              const appointmentRef = doc(firestore, 'appointments', appointment.id)

              const appointmentDoc = await getDoc(appointmentRef)
              const studentDoc = await getDoc(studentRef)

              if(!appointmentDoc.exists() || !studentDoc.exists()) {
                console.error(`De registratie voor student ${studentID} is nog niet opgeslagen in de database. Zorg ervoor dat deze bestaat om deze te updaten.`)
                return
              }

              //#endregion

              /**
               * Because the scans are inside an array in Firestore, we can't use the index to select them
               * as we do with the `studentID` inside the database. So we need to iterate over all the scans
               * and update the one that has a new context.
               * 
               * The scans only get pushed to the database when the `ctxLocation` is set to `scan`. Any undefined
               * values will be ignored by Firestore in an update.
               */
              const updatedScans:Array<Scan> = registration.scans.map((s, idx) => {
                if(idx === scanIndex) return {
                  ...s,
                  ctx: newCtx
                } 
                else return s
              })

              const batch = writeBatch(firestore)

              batch.update(studentRef, {
                ctx: ctxLocation === 'appointment' ? newCtx : undefined,
                scans: ctxLocation === 'scan' ? updatedScans : undefined
              })
              batch.update(appointmentRef, {
                [`attendance.registrations.${studentID}.ctx`]: ctxLocation === 'appointment' ? newCtx : undefined,
                [`attendance.registrations.${studentID}.scans`]: ctxLocation === 'scan' ? updatedScans : undefined
              })

              try {
                await batch.commit()
                if(!silent) toast(`Verantwoording succesvol ${newCtx ? 'geüpdated' : 'verwijderd'}.`, { type: newCtx ? 'success' : 'warning' })
              }
              catch(err) {
                console.error(err)
                if(!silent) toast(`Er is een fout opgetreden bij het updaten van de verantwoording.`, { type: 'error' })
                return;
              }

            }

          },
          addFromOptionals: async (studentID, appointment, silent = false) => {

            if(!appointment.attendance.optionals.includes(studentID)) {
              toast(`Deze student is niet gevonden in de optionele studentenlijst van deze afspraak.`, { type: 'warning' })
              return;
            }

            const result = await trpx.appointment.addFromOptionals.mutate({ studentID, appointmentID: appointment.id });

            if(result.error) {
              if(!silent) {
                toast(result.error.message, { type: 'error' })
              } 
              console.error(result.error);
              return;
            }
            else if (!silent) {
              toast(`Student succesvol toegevoegd aan de huidige afspraak.`, { type: 'success' })
            }
          
          }
        },

        finalize: async (appointment) => {

          const status = schedule.getStatus(appointment)

          if(status !== 'expired') {
            console.error(`De afspraak ${appointment.id} kan niet gefinaliseerd worden, omdat deze (nog) niet verlopen is.`)
            return;
          }

          /**
           * These registrations are from students that have forgotten to check out.
           * Add a new scan time for these students, with a check-out time set to the end of the appointment.
           * Also add a context to the registration, so it is clear that the student was automatically checked out.
           */
          const unresolvedRegistrations = Object
            .entries(appointment.attendance.registrations)
            .map(([id, registration]) => registration as Registration<'appointment'>)
            .filter((registration) => registration.scans.at(-1)?.type === 'in')
          
          // Write new scans to the database
          await Promise.all(unresolvedRegistrations.map(async (registration) => {
            
            /**
             * Make the updates to Firestore in a batch, so we can be sure that either
             * all updates are successful, or none of them are. This is important to prevent
             * the same piece of data having different states in different places in the database.
             */
            const batch = writeBatch(firestore)

            const studentRef = doc(firestore, 'students', registration.student.id, 'registrations', appointment.id)
            const appointmentRef = doc(firestore, 'appointments', appointment.id)

            const updatedScans:Array<Scan> = [
              ...registration.scans,
              {
                type: 'out',
                time: moment(appointment.time.end).add(appointment.time.suffix_mins, 'minutes').toISOString(),
                ctx: 'Automatisch toegevoegd; leerling is zich vergeten uit te checken.'
              }
            ]

            batch.update(appointmentRef, {
              [`attendance.registrations.${registration.student.id}.scans`]: updatedScans
            })
            batch.update(studentRef, {
              scans: updatedScans
            })

            try {
              await batch.commit()
            }
            catch(err) {
              console.error(err);
            }
          }))

        }

      })
    ),
    {
      name: 'zustand:appointment-storage',
      storage: createJSONStorage(() => sessionStorage),
      partialize: (state) => ({
        unresolvedScans: {
          cache: state.unresolvedScans.cache
        }
      }),
      merge: (persisted, current) => merge(current, persisted)
    }
  )
)

if(env.NEXT_PUBLIC_NODE_ENV === 'development') {
  mountStoreDevtool('AppointmentStore', useAppointmentStore)
}