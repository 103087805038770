import { Appointment } from "@skip-scanner/toolkit/database";
import { ScheduleProvider, IScheduleProvider } from "./base";
import { TRPCError } from "@trpc/server";
import moment from "moment";
import { getSession } from "next-auth/react";
import { trpx } from "lib/trpc";

/**
 * The **MockScheduleProvider** class is an implementation of the **ScheduleProvider** class.
 * It creates a fake and randomized schedule with default values that are provided by the **Mock** class.
 * 
 * Classes of students are predefined with "fake" students, subjects and locations.
 * These values can be used for demonstration purposes, for instance by using real student cards
 * with the values of the Mock classes.  
 */
export class MockScheduleProvider extends ScheduleProvider {

  public sync: IScheduleProvider['sync'] = async (week) => {

    const weekNumber = week ?? moment().isoWeek();
    const session = await getSession();

    if(!session) {
      throw new TRPCError({
        code: 'UNAUTHORIZED',
        message: 'Log gelieve opnieuw in om uw rooster op te halen vanuit Skip Scanner.'
      })
    }

    const appointments = await trpx.modules.schedule.mock.sync.mutate({ weekNumber });
    this.updateCache(weekNumber, appointments, 'sync')
    
    return appointments;
  };

  constructor() {
    super();
  }
}