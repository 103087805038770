import React from 'react'
import moment from 'moment'
import { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import { Session } from 'next-auth'
import { trpc } from 'lib/trpc'
import { ToastProvider } from '@skip-scanner/ui'
import { Analytics as VercelAnalytics } from '@vercel/analytics/react'
import { 
  NextFirebaseAuthProvider, 
  GlobalStoreProvider, 
  HardwareScanProvider 
} from 'lib/providers'

import '../styles/globals.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'moment/min/locales'
import 'reactflow/dist/style.css'

moment.locale('nl')

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps<{ session: Session }>) {

  return (
    <SessionProvider 
      session={session} 
      refetchOnWindowFocus={true}
      refetchInterval={60 * 10} // 10 minutes
    >
      <NextFirebaseAuthProvider>
        <GlobalStoreProvider>

            {/* Integratie van Vercel */}
            <VercelAnalytics />
            
            {/* App overlays */}
            <ToastProvider />
            
            {/* Main component */}
            <Component {...pageProps}/>   

        </GlobalStoreProvider>
      </NextFirebaseAuthProvider>
    </SessionProvider>
  )
}

export default trpc.withTRPC(MyApp)
