import { create } from "zustand";
import { persist, createJSONStorage } from 'zustand/middleware'
import type { Tenant } from '@skip-scanner/toolkit/database'
import { TRPCError } from '@trpc/server'
import { trpx } from 'lib/trpc'

type TenantState = {

  /**
   * If the tenant is currently being fetched from the tRPC API.
   */
  status: 'idle' | 'fetching',

  /**
   * The different mail domains that can be used for registration of a user.
   */
  allowedMailDomains: Tenant['allowedMailDomains'],
  
  /**
   * The different default lesson time additions that are added to each lesson.
   */
  scanTimeAdditions: Tenant['scanTimeAdditions'] | undefined,
  
  /**
   * Tenant ID + address and full school name.
   */
  portalEnvironment: Tenant['portalEnvironment'] | undefined,
  
  /**
   * License information and validity checks.
   */
  license: Tenant['license'] | undefined,

  /**
   * Fetches the tenant from the tRPC API, and stores this inside sessionStorage.
   */  
  fetchTenant: () => Promise<void>
}

/**
 * A zustand store hook that provides and manages tenant states, 
 * including utilities for fetching tenant data.
 * The states are persisted in the sessionStorage.
 * 
 * @example
 * const { allowedMailDomains, fetchTenant } = useTenantStore();
 */
export const useTenantStore = create<TenantState>()(
  persist(
    (set, get) => ({
      
      status: 'idle',

      allowedMailDomains: [],
      scanTimeAdditions: undefined,
      portalEnvironment: undefined,
      license: undefined,

      /**
       * Fetches the tenant from the tRPC API, and stores this inside sessionStorage.
       */
      fetchTenant: async () => {

        if(get().status == 'fetching') return;

        try {
          set({ status: 'fetching' })
          const data = await trpx.tenant.fetchPublicData.query({})
          set({ 
            allowedMailDomains: data.allowedMailDomains,
            portalEnvironment: data.portalEnvironment,
            scanTimeAdditions: data.scanTimeAdditions,
            license: data.license
          })
        }
        catch(err) {
          const error = err as TRPCError
          console.error(error.message)
          set({
            allowedMailDomains: undefined,
            portalEnvironment: undefined,
            scanTimeAdditions: undefined,
            license: undefined,
          })
        }
        finally {
          setTimeout(() => {
            set({ status: 'idle' })
          }, 500);
        }
      }

    }),
    {
      name: 'zustand:tenant-storage',
      storage: createJSONStorage(() => 
        sessionStorage
      )
    }
  )
)