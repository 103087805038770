import React from 'react';
import { useScheduleStore } from 'lib/stores';
import type { ProviderProps } from 'lib/providers';
import { useAsync } from 'react-use';

/**
 * `ScheduleSyncProvider` is a React component that acts as a provider to automatically
 * enable the synchronization of appointments using the cache.
 * 
 * ---
 * @param {ProviderProps} props - The props for the component.
 * @param {React.ReactNode} props.children - The child components that this provider will wrap.
 * @returns {JSX.Element} The children wrapped within the provider.
 * ---
 * @example
 * ```tsx
 * <ScheduleSyncProvider>
 *   <YourComponent /> 
 * </ScheduleSyncProvider>
 * ```
 */
export const ScheduleSyncProvider:React.FC<ProviderProps> = ({ children }) => {

  const { cachedSync } = useScheduleStore(state => state.sync)

  /**
   * Initialize the sync of appointments using the cache on mount.
   * It starts the timer and syncs the appointments if no appointments are present
   * or if no last scan is present.
   */
  useAsync(cachedSync, [])

  return (<> {children} </>)
}