import React, { Fragment } from 'react'
import { CardStyles } from './Card'
import { Text } from './Text'
import { Button } from './Button'
import { cva, VariantProps } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import { Dialog, Transition } from '@headlessui/react'
import { omit } from 'lodash'
import { confirmable, createConfirmation, type ConfirmDialogProps } from 'react-confirm'

//#region Dialog component design

  type ModalComponentProps = 
    {
      title: string,
      desc: string,
      cancelText?: string | null,
      confirmText?: string
    }

  const DialogComponent:React.FC< ConfirmDialogProps<ModalComponentProps, boolean> > = 
    ({ title, desc, cancelText = 'Annuleren', confirmText = 'Doorgaan', proceed, show }) => {

    return (
      <Transition.Root show={show} as={Fragment} appear={true}>
        <Dialog as='div' className='relative z-50' onClose={() => proceed(false)}>

          {/* Backdrop */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-neutral-800 bg-opacity-40 transition-opacity" />
          </Transition.Child>

          {/* Full-screen container to center the panel */}
          <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
            
            {/* The actual dialog */}
            <Dialog.Panel className={ twMerge('max-w-md w-[35vw] min-h-[30vh] relative !rounded-xl !p-0', CardStyles()) }>

              {/* Main content */}
              <div className="absolute top-0 inset-x-0 bottom-[4.5rem] pt-4 px-6 flex flex-col">
              
                <Text.Label className='text-heading-xs mt-3 from-primary-darker to-primary' textColor={'gradient-to-b'}>
                  {title}
                </Text.Label>

                <hr className='my-4 border-neutral-200'/>

                <Text.Paragraph className='flex-1 text-paragraph-sm text-neutral-500'>
                  {desc}
                </Text.Paragraph>

              </div>

              {/* Bottom buttons */}
              <div className="absolute bottom-0 w-full h-[4.5rem] inset-x-0 px-6 py-4 gap-x-4 flex">

                {cancelText !== null && (
                  <button className='border-2 rounded-md text-sm items-center justify-center flex text-neutral-600 group flex-1' onClick={() => proceed(false)}>
                    <span className="group-hover:scale-105 group-active:scale-95 transition-transform">
                      {cancelText ?? 'Annuleren'}
                    </span>
                  </button>                  
                )}

                <button 
                  className='flex-1 rounded-md text-sm items-center justify-center flex bg-primary text-white font-medium group mx-auto max-w-[55%]'
                  onClick={() => proceed(true)}
                >
                  <span className="group-hover:scale-105 group-active:scale-95 transition-transform">
                    {confirmText ?? 'Doorgaan'}
                  </span>
                </button>

              </div>

            </Dialog.Panel>

          </div>

        </Dialog>
      </Transition.Root>
    )
  }

  /**
   * The final, usable dialog component by `react-confirm`.
   * You import this in all your `react-confirm` functions
   */
  const Modal = confirmable(DialogComponent)

//#endregion

export const confirm = (title: string, desc: string, opts?: Omit<ModalComponentProps, 'title' | 'desc'>) => {
  const confirm = createConfirmation(Modal)
  return confirm({ title, desc: desc, cancelText: opts?.cancelText, confirmText: opts?.confirmText })
} 