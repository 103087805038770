import React, { useEffect } from "react"
import { useTenantStore } from "lib/stores"
import type { ProviderProps } from ".."

/**
 * `GlobalStoreProvider` creates an abstraction of handling application-wide state fetching via Zustand stores.
 * It checks if specific variables are undefined and fetches the data using the complementary provided functions.
 * 
 * @param {ProviderProps} props - The props for the component.
 * @param {React.ReactNode} props.children - The child components that this provider will wrap.
 *
 * @example
 * ```tsx
 * <GlobalStoreProvider>
 *   <YourComponent />
 * </GlobalStoreProvider>
 * ```
 * @returns {JSX.Element} The children wrapped within the provider.
 */
export const GlobalStoreProvider:React.FC<ProviderProps> = ({ children }) => {

  const { portalEnvironment, fetchTenant } = useTenantStore()

  useEffect(() => {
    if(portalEnvironment == undefined) fetchTenant()
  }, [ portalEnvironment ])

  return (<> {children} </>)
}