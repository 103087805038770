import React, { useEffect } from 'react'
import { auth } from '@skip-scanner/toolkit/firebase/client'
import { signOut, signInWithCustomToken } from 'firebase/auth'
import { useSession } from 'next-auth/react'
import type { ProviderProps } from 'lib/providers'
import { useRouter } from 'next/router'

/**
 * `NextFirebaseAuthProvider` is a React component that acts as an authentication provider.
 * It integrates NextAuth's session state with Firebase's authentication, making it easier 
 * to synchronize user authentication state between the two services.
 * 
 *
 * @param {ProviderProps} props - The props for the component.
 * @param {React.ReactNode} props.children - The child components that this provider will wrap.
 *
 * @example
 * ```tsx
 * <NextFirebaseAuthProvider>
 *   <YourComponent />
 * </NextFirebaseAuthProvider>
 * ```
 * @returns {JSX.Element} The children wrapped within the provider.
 */
export const NextFirebaseAuthProvider:React.FC<ProviderProps> = ({ children }) => {

  // Check for NextAuth callback and handle Firebase Data accordingly
  const { data: session, status: sessionStatus } = useSession()
  const { replace } = useRouter()

  useEffect(() => {
    const handleAuthStatusChange = async () => {
      
      // Login to Firebase
      if(sessionStatus == 'authenticated') {
        await signInWithCustomToken(auth, session.firebaseToken)
      }
      
      // Logout to Firebase
      else if(sessionStatus == 'unauthenticated') {
        window.sessionStorage.clear()
        await signOut(auth)

        await replace('/auth')
      }

    }

    handleAuthStatusChange();
  }, [sessionStatus])

  return (<> {children} </>)
}

